<template>
  <div>
    <template v-if="p_machineLearningResult && p_machineLearningResult.length > 0">
      <template v-for="(result_item, result_item_index) in p_machineLearningResult">
        <b-card v-for="(ml, ml_index) in result_item.machine_learning" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;" :key="'machine_learning_' + ml_index.toString()">
          <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
            <b-row>
              <b-col cols="6">
                Makine Öğrenmesi {{ ml.type }}, <small>{{ ml.lib }}</small>
              </b-col>
              <b-col cols="6" style="text-align: right">
                <b-dropdown variant="success" size="sm">
                  <b-dropdown-item @click="f_predictML(ml)">Tahmin Yap</b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-card-header>
          <div>
            <table style="margin: 10px; margin-bottom: 3px; width: 70%;">
              <thead>
                <tr style="text-align: center; border: solid 1px #c3d8d8; background-color: #b5f8e2;">
                  <th style="border: solid 1px #c3d8d8; min-width: 150px;"></th>
                  <th style="border: solid 1px #c3d8d8; min-width: 150px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <strong> Confusion Matrix (Hata Matrisi) </strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <template v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0">
                      <table style="width: 100%;">
                        <tr>
                          <th style="text-align: center;"></th>
                          <th colspan="1" style="text-align: center;"></th>
                          <th colspan="2" style="text-align: center;">Gerçek Değerler</th>
                          <th style="text-align: center;"></th>
                        </tr>
                        <tr>
                          <th style="text-align: center;"></th>
                          <th style="text-align: center;"></th>
                          <th style="text-align: center; color: green;">Pozitif</th>
                          <th style="text-align: center; color: red;">Negatif</th>
                          <th style="text-align: center;">Total</th>
                        </tr>
                        <tr>
                          <td rowspan="2" style="text-align: center;"><strong>Tahmin Değerleri</strong></td>
                          <td style="text-align: center; color: green;"><strong>Pozitif</strong></td>
                          <td style="text-align: center;" class="confusion-matrix" title="Doğru Pozitif: Pozitif olana model de pozitif demiştir. Doğru tahmin.">{{ ml.confusion_matrix[0][0] }}</td>
                          <td style="text-align: center;" class="confusion-matrix" title="Yanlış Pozitif: Aslında negatif olana model pozitif demiştir. Yanlış tahmin">{{ ml.confusion_matrix[0][1] }}</td>
                          <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][0]) + parseInt(ml.confusion_matrix[0][1]) }}</td>
                        </tr>
                        <tr>
                          <td style="text-align: center; color: red;"><strong>Negatif</strong></td>
                          <td style="text-align: center;" class="confusion-matrix" title="Yanlış Negatif: Aslında pozitif olana model negatif demiştir. Yanlış tahmin">{{ ml.confusion_matrix[1][0] }}</td>
                          <td style="text-align: center;" class="confusion-matrix" title="Doğru Negatif: Negatif olana model de negatif demiştir. Doğru tahmin">{{ ml.confusion_matrix[1][1] }}</td>
                          <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[1][0]) + parseInt(ml.confusion_matrix[1][1]) }}</td>
                        </tr>
                        <tr>
                          <td style="text-align: center;"></td>
                          <td style="text-align: center;">Total</td>
                          <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][0]) + parseInt(ml.confusion_matrix[1][0]) }}</td>
                          <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][1]) + parseInt(ml.confusion_matrix[1][1]) }}</td>
                          <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][0]) + parseInt(ml.confusion_matrix[0][1]) + parseInt(ml.confusion_matrix[1][0]) + parseInt(ml.confusion_matrix[1][1]) }}</td>
                        </tr>
                      </table>
                    </template>
                    <template v-else>
                      Hata matrisi yok.
                    </template>
                  </td>
                </tr>
                <tr v-if="ml.cluster_centers_ && ml.cluster_centers_.length > 0">
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <strong> Küme merkezleri </strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <table style="width: 100%;">
                      <tr>
                        <th></th>
                        <th v-for="(input_var, input_var_ind) in ml.input_variables" class="confusion-matrix">
                          <span :title="input_var">
                            {{ input_var.length > 10 ? input_var.substring(0, 10) + '...' : input_var }}
                          </span>
                        </th>
                      </tr>
                      <tr v-for="(rw_cluster, rw_cluster_ind) in ml.cluster_centers_">
                        <td>{{ rw_cluster_ind + 1 }} . küme</td>
                        <td v-for="(col_cluster, col_cluster_ind) in rw_cluster">
                          <span :title="col_cluster">{{ parseFloat(col_cluster).toFixed(2) }}</span>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr v-if="ml.test_size !== undefined">
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <strong>Test Boyutu</strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;" class="confusion-matrix">
                    {{ ml.test_size }}
                  </td>
                </tr>
                <tr v-if="ml.random_state !== undefined">
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <strong>Random State</strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;" class="confusion-matrix">
                    {{ ml.random_state }}
                  </td>
                </tr>
                <tr v-if="ml.kernel_type !== undefined">
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <strong>Kernel Tipi</strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;" class="confusion-matrix">
                    {{ ml.kernel_type }}
                  </td>
                </tr>
                <tr v-if="ml.init !== undefined">
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <strong>Init</strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;" class="confusion-matrix">
                    {{ ml.init }}
                  </td>
                </tr>
                <tr v-if="ml.n_clusters !== undefined">
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <strong></strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;" class="confusion-matrix">
                    {{ ml.n_clusters }}
                  </td>
                </tr>
                <tr>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <strong>Accuracy (doğruluk)</strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;" class="confusion-matrix" :title="ml.accuracy">
                    % {{ (ml.accuracy * 100).toFixed(0) }}
                  </td>
                </tr>
                <tr v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0">
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;" class="confusion-matrix" title="Doğru pozitifler / (Doğru pozitifler + Yanlış pozitifler)">
                    <strong>Precision (Kesinlik)</strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    {{ (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[0][1]))).toFixed(2) }}
                  </td>
                </tr>
                <tr v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0">
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;" class="confusion-matrix" title="Doğru pozitifler / (Doğru pozitifler + Yanlış negatifler)">
                    <strong>Recall (Duyarlılık)</strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    {{ (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[1][0]))).toFixed(2) }}
                  </td>
                </tr>
                <tr v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0">
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;" class="confusion-matrix" title="2 * (Kesinlik * Duyarlılık) / (Kesinlik + Duyarlılık)">
                    <strong>F1 skoru</strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    {{ ( 2 * ( (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[0][1]))) * (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[1][0]))) ) / ( (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[0][1]))) + (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[1][0]))) ) ).toFixed(2) }}
                  </td>
                </tr>
                <tr v-if="ml.roc && ml.roc.auc">
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <strong> ROC (auc) </strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    {{ (ml.roc.auc).toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                    <strong>Tahmin Yap</strong>
                  </td>
                  <td style="border: solid 1px #c3d8d8; min-width: 150px;" class="confusion-matrix" title="Girdi değerleri girilerek tahmin yapılması işlemi gerçekleştiriliyor.">
                    <b-button variant="white" style="background: #cdf7f4; width: 100%;" @click="f_predictML(ml)">Modelle Test Yap</b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="width: 100%" v-for="(fig, fig_ind) in ml.figure_list">
            <img :src="fig.figure" style="width: 50%;">
          </div>
        </b-card>
      </template>
    </template>
    <b-modal id="d_selectedMachineLearningdata" v-model="d_selectedMachineLearningdata.show" scrollable title="Makine Öğrenmesi Analiz Ekranı" centered header-bg-variant="info" header-text-variant="dark" size="md">
      <b-row>
        <b-col sm="12" md="12">
          <machine-learning-predict :p_mlData="d_selectedMachineLearningdata.data"></machine-learning-predict>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import MachineLearningService from '@/services/machine_learning';
import {
  default as MachineLearningPredict
} from "@/components/widgets/MachineLearningPredict";
import PoleStarService from '@/services/polestar';
export default {
  name: 'MachineLearningResult',
  components: {
    MachineLearningPredict
  },
  props: {
    column_list: {
      type: Array,
      required: false
    },
    p_machineLearningResult: {
      type: Array,
      required: true
    }
  },
  data: () => {
    return {
      d_selectedMachineLearningdata: { 'show': false, 'data': {} },
    }
  },
  computed: {},
  methods: {
    f_predictML: function (ml_data) {
      // console.log("ml_data", ml_data);
      let data = {
        'trained_model_pickle': ml_data.trained_model !== undefined ? ml_data.trained_model_pickle : '',
        'trained_model': ml_data.trained_model !== undefined ? ml_data.trained_model : '',
        'accuracy': ml_data.accuracy !== undefined ? ml_data.accuracy : '',
        'confusion_matrix': ml_data.confusion_matrix !== undefined ? ml_data.confusion_matrix : '',
        'filename': ml_data.filename !== undefined ? ml_data.filename : '',
        'lib': ml_data.lib !== undefined ? ml_data.lib : '',
        'roc': ml_data.roc !== undefined ? ml_data.roc : '',
        'type': ml_data.type !== undefined ? ml_data.type : '',
        'input_variables': ml_data.input_variables !== undefined ? ml_data.input_variables : '',
        'target': { 'label': '.....' },
        'input_list': []
      };
      let target_column_index_list = Object.keys(ml_data.data.cc);
      if (target_column_index_list.length > 0) {
        let target_column_index = target_column_index_list[0];
        if (this.column_list) {
          data.target.label = this.column_list[target_column_index].label
        } else if (ml_data.data.cc[target_column_index].name) {
          data.target.label = ml_data.data.cc[target_column_index].name
        }
        if (ml_data.data.cc[target_column_index].options && Object.keys(ml_data.data.cc[target_column_index].options).length > 0) {
          for (let i in ml_data.data.cc[target_column_index].options) {
            let t = {
              'label': i,
              'value': ml_data.data.cc[target_column_index].options[i].group_val
            };
            if (!data.target.options) {
              data.target.options = [];
            }
            data.target.options.push(t);
          }
        }
      }
      for (let i in ml_data.input_variables) {
        for (let rc_ind in ml_data.data.rc) {
          if (ml_data.data.rc[rc_ind].name === ml_data.input_variables[i]) {
            let input_column_data_label = '';
            if (this.column_list) {
              input_column_data_label = this.column_list[rc_ind].label;
            } else if (ml_data.data.rc[rc_ind].name) {
              input_column_data_label = ml_data.data.rc[rc_ind].name;
            }
            let pr_type = '';
            if (ml_data.data.rc[rc_ind].options && Object.keys(ml_data.data.rc[rc_ind].options).length > 0) {
              pr_type = 'select';
            } else {
              pr_type = 'float';
            }
            let prepare_input_data = {
              'label': input_column_data_label,
              'param_type': pr_type,
              'options': []
            };
            if (ml_data.data.rc[rc_ind].options) {
              for (let o in ml_data.data.rc[rc_ind].options) {
                let opt_data = { 'label': o, 'value': ml_data.data.rc[rc_ind].options[o].group_val };
                prepare_input_data.options.push(opt_data);
              }
            }
            data.input_list.push(prepare_input_data);
            break;
          }
        }
      }
      this.d_selectedMachineLearningdata.data = data;
      this.d_selectedMachineLearningdata.show = true;
    },
  }
}

</script>

