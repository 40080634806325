import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_wdm22_wdm_list (query) {
    return Vue.http.get(API_BASE_URL + 'get_wdm22_wdm_list?' + query);
  },
  create_new_wdm (data) {
    return Vue.http.put(API_BASE_URL + 'create_new_wdm', data);
  },
  save_wdm_data (data) {
    return Vue.http.put(API_BASE_URL + 'save_wdm_data', data);
  },
  get_related_wdmr_list (data) {
    return Vue.http.post(API_BASE_URL + 'get_related_wdmr_list', data);
  },
  analyze_text_for_wdm (data) {
    return Vue.http.post(API_BASE_URL + 'analyze_text_for_wdm', data);
  },
  get_wdm_list (data) {
    return Vue.http.post(API_BASE_URL + 'get_wdm_list', data);
  },
  get_wdm_parameter_option (query, data) {
    return Vue.http.post(API_BASE_URL + 'get_wdm_parameter_option?' + query, data);
  },
  get_wdm_last_list (data) {
    return Vue.http.post(API_BASE_URL + 'get_wdm_last_list', data);
  },
  save_child_wdmr (data) {
    return Vue.http.put(API_BASE_URL + 'save_child_wdmr', data);
  },
  publish_wdm (data) {
    return Vue.http.put(API_BASE_URL + 'publish_wdm', data);
  },
  add_chart_to_wdmr_as_figure (data) {
    return Vue.http.put(API_BASE_URL + 'wdmr/add_chart_to_wdmr_as_figure', data);
  },
  add_table_to_wdmr (data) {
    return Vue.http.put(API_BASE_URL + 'wdmr/add_table_to_wdmr', data);
  },
  delete_table_wdmr (data) {
    return Vue.http.put(API_BASE_URL + 'wdmr/delete_table_wdmr', data);
  },
  delete_child_wdmr (data) {
    return Vue.http.put(API_BASE_URL + 'wdmr/delete_child_wdmr', data);
  },
  date_adjust (data) {
    return Vue.http.post(API_BASE_URL + 'wdmr/date_adjust', data);
  },
  get_wdmr_tree_list (data) {
    return Vue.http.post(API_BASE_URL + 'wdmr/get_wdmr_tree_list', data);
  },
  get_wdmr_tree_list_remote (data) {
    return Vue.http.post(API_BASE_URL + 'wdmr/get_wdmr_tree_list_remote', data);
  }
};
