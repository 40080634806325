<template>
  <div>
    <b-card no-body header-tag="header" style="margin-bottom: 3px;" :class="[d_fullScreenResults ?'full-screen-mode' :'normal-mode']">
      <b-card-header v-if="p_mode !== 'text'" header-bg-variant="white" class="p-1" header-text-variant="dark">
        <b-row>
          <b-col sm="12" lg="6">
            <b-button size="md" :variant="d_fullScreenResults ?'warning' :'white'" :style="d_fullScreenResults ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_fullScreenResults ? d_fullScreenResults = false : d_fullScreenResults = true">
              <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
            </b-button>
            <b-button size="sm" variant="white" style="margin-right: 5px; padding: 0px;" @click="f_changeResultsDevide()" :title="'Sonuç ekran görünüm bölme'">
              <img src="@/icon/2349601.png" style="width: 2.5em;" /> </b-button>
            <b-button size="sm" :variant="d_showMode.show_charts ? 'warning' : 'white'" style="margin-right: 5px; padding: 0px;" @click="d_showMode.show_charts ? d_showMode.show_charts = false : d_showMode.show_charts = true" :title="'Grafikleri göster'">
              <img src="@/icon/2500447.png" style="width: 2.5em;" /> </b-button>
            <b-button size="sm" :variant="d_showMode.show_table ? 'warning' : 'white'" style="margin-right: 5px; padding: 0px;" @click="d_showMode.show_table ? d_showMode.show_table = false : d_showMode.show_table = true" :title="'Tabloları göster'">
              <img src="@/icon/2751062.png" style="width: 2.5em;" /> </b-button>
            <b-button size="sm" :variant="d_showMode.show_stats ? 'warning' : 'white'" style="margin-right: 5px; padding: 0px;" @click="d_showMode.show_stats ? d_showMode.show_stats = false : d_showMode.show_stats = true" :title="'İstatistikleri göster'">
              <img src="@/icon/986801.png" style="width: 2.5em;" /> </b-button>
            <b-button size="sm" :variant="d_showMode.general_stats ? 'warning' : 'white'" style="margin-right: 5px; padding: 0px;" @click="d_showMode.general_stats ? d_showMode.general_stats = false : d_showMode.general_stats = true" :title="'Genel analizleri göster'">
              <img src="@/icon/1425410.png" style="width: 2.5em;" /> </b-button>
            <b-button size="sm" :variant="d_showMode.show_text ? 'warning' : 'white'" style="margin-right: 5px; padding: 0px;" @click="d_showMode.show_text ? d_showMode.show_text = false : d_showMode.show_text = true" :title="'Makale & Rapor yazılarını göster'">
              <img src="@/icon/487464.png" style="width: 2.5em;" /> </b-button>
            <b-button size="sm" variant="white" style="margin-right: 5px; padding: 0px;" @click="f_openCloseAllResults()" :title="'Sonuçlar Aç - Kapa'">
              <img src="@/icon/824448.png" style="width: 2.5em;" /> </b-button>
            <template v-if="d_selectedWdm72Key">
              <b-button size="sm" :variant="d_showMode.show_stars ? 'warning' : 'white'" style="margin-right: 5px; padding: 0px;" @click="d_showMode.show_stars ? d_showMode.show_stars = false : d_showMode.show_stars = true" :title="'Skorları göster'">
                <img src="@/icon/934385.png" style="width: 2.5em;" /> </b-button>
              <b-button v-if="d_selectedWdm72Key" size="sm" variant="white" style="margin-right: 5px; padding: 0px;" @click="d_filterAnalyzeData.show = true" :title="'Sonuçları Filtreleme Ekranı'">
                <img src="@/icon/1517831.png" style="width: 2.5em;" /> </b-button>
              <b-dropdown variant="white" size="sm" style="margin: 5px;">
                <b-dropdown-item @click="f_createPDFofWdm72Results(d_selectedWdm72Key, 'filtered')">
                  <img src="@/icon/950780.png" title="filtrelenmiş sonuçlara ait pdf üret" style="width: 2em;" /> Filtreli Pdf (tümü)
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-col>
          <b-col sm="12" lg="6">
            <span v-if="p_selectedWdm72Results.length > 0">Listelenen: <i>{{ d_wdm72Pagination.start + 1 }} - {{ d_wdm72Pagination.end }} </i> </span>
            <span v-else>Sonuç Yok</span> / {{ d_selectedWdm72Key ? d_wdm72Pagination.all_count : p_selectedWdm72Results.length }}
            <b-pagination size="sm" class="pull-right" style="margin-bottom: 3px;" aria-controls="wdm72pagination" v-model="d_wdm72Pagination.current" :total-rows="d_wdm72Pagination.all_count" :per-page="d_wdm72Pagination.perpage"></b-pagination>
          </b-col>
        </b-row>
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col cols="3" style="padding: 3px;">
          <b-card no-body style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px; border: solid 2px #00bcd4; height: 100%;">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              <strong>Analiz listesi</strong>
            </b-card-header>
            <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
              <div :style="f_calculateItemHeaderStyle()" @click="f_cancelWdm72ResultSelection()"><strong> Tümü </strong></div>
              <div v-for="(wdm72_result, wdm72_result_ind) in p_selectedWdm72Results" :style="f_calculateItemHeaderStyle(wdm72_result_ind)" @click="f_selectWdm72Result(wdm72_result_ind)">
                <strong>{{ wdm72_result.no }} -) </strong>
                <template v-if="wdm72_result.rc_ind !== undefined && wdm72_result.rc_ind !== ''">
                  {{ column_list[wdm72_result.rc_ind].label }} ({{ wdm72_result.rc_ind + 1 }}) & {{ column_list[wdm72_result.cc_ind].label }} ({{ wdm72_result.cc_ind + 1 }})
                </template>
                <template v-else-if="wdm72_result.col_index !== undefined">
                  {{ column_list[wdm72_result.col_index].label }}
                </template>
                <template v-else-if="wdm72_result.rc_list !== undefined">
                  <template v-for="(rc_list_col, rc_list_col_ind) in wdm72_result.rc_list">
                    {{ column_list[rc_list_col].label }},
                  </template> =>
                  <template v-for="(cc_list_col, cc_list_col_ind) in wdm72_result.cc_list">
                    {{ column_list[cc_list_col].label }},
                  </template>
                </template>
                <template v-else-if="wdm72_result.multi_dim !== undefined">
                  <template v-for="rc_list_col_ind in wdm72_result.multi_dim.rc_col_order">
                    {{ column_list[rc_list_col_ind].label }},
                  </template> =>
                  <template v-for="cc_list_col_ind in wdm72_result.multi_dim.cc_col_order">
                    {{ column_list[cc_list_col_ind].label }},
                  </template>
                  <b-badge variant="white" style="margin-left: 5px;">[multi dimension]</b-badge>
                </template>
                <b-badge variant="white" style="margin-left: 5px;">{{ wdm72_result.analyze_type }}</b-badge>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col cols="9" style="padding: 3px;">
          <b-card no-body style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px; border: solid 2px #00bcd4; height: 100%;">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              <strong>Analiz Detayları</strong>
            </b-card-header>
            <div style="overflow-x: hidden; overflow-y: auto; height: 500px;">
              <template v-if="p_mode && p_mode === 'text'">
                <template v-for="(wdm72_result, wdm72_result_ind) in p_selectedWdm72Results">
                  <b-row v-if="d_selectedWdm72ResultIndex === '' || wdm72_result_ind === d_selectedWdm72ResultIndex" :key="'wdm72_result_p_mode_text_' + wdm72_result_ind.toString()">
                    <b-col sm="12" md="8">
                      <template v-if="wdm72_result.text_list && wdm72_result.text_list.length > 0">
                        <b-row v-for="(text, text_ind) in wdm72_result.text_list" :key="'wdm72_result.text_list_' + text_ind + '_1'">
                          <b-col cols="12">
                            <span v-if="text.text.length > 100" style="cursor: pointer;" @click="f_showTableCellFullText(text.text)">
                    {{ text.text.substring(0, 100) + '...' }}
                  </span>
                            <span v-else>
                    {{ text.text }}
                  </span>
                          </b-col>
                        </b-row>
                      </template>
                    </b-col>
                    <b-col sm="12" md="4">
                      <template v-if="['skewness', 'kurtosis', 'kolmogorov_smirnov', 'shapiro_wilk' ,'outliers_interquartile_range','outliers_zindex','mean','median','frequency','sum','min','max'].indexOf(wdm72_result.analyze_type) !== -1">
                        <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_generalInformationWdm72_result(wdm72_result)">
                          <img src="@/icon/2521497.png" title="Genel Bilgiler" style="width: 2em;" />
                        </b-button>
                      </template>
                      <template v-if="wdm72_result.table_data">
                        <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showTableData(wdm72_result.table_data)">
                          <img src="@/icon/2751062.png" title="Tablo Göster - 1" style="width: 2em;" />
                        </b-button>
                      </template>
                      <template v-if="wdm72_result.table">
                        <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showTableFullScreen(wdm72_result.table)">
                          <img src="@/icon/2751062.png" title="Grafik" style="width: 2em;" />
                        </b-button>
                      </template>
                      <template v-if="wdm72_result.frequency_table">
                        <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showFrequencyTable(wdm72_result.frequency_table)">
                          <img src="@/icon/2751062.png" title="Tablo Göster - Sıklık" style="width: 2em;" />
                        </b-button>
                      </template>
                      <template v-if="wdm72_result.charts && wdm72_result.charts.length > 0">
                        <b-button v-for="(chart, chart_index) in wdm72_result.charts" :key="'wdm72_result.charts_1_' + chart_index" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showChartData(chart)">
                          <img src="@/icon/2500447.png" title="Grafik" style="width: 2em;" />
                        </b-button>
                      </template>
                      <template v-if="wdm72_result.frequency_table && wdm72_result.frequency_table.length > 0">
                        <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_showChartViewFromTable(wdm72_result.frequency_table, 'bar', true, true)">
                          <img src="@/icon/2500447.png" title="Grafik - Sıklık" style="width: 2em;" />
                        </b-button>
                      </template>
                    </b-col>
                  </b-row>
                </template>
              </template>
              <template v-if="p_mode !== 'text'">
                <b-row v-for="(wdm72_result, wdm72_result_ind) in p_selectedWdm72Results" v-if="d_selectedWdm72ResultIndex === '' || wdm72_result_ind === d_selectedWdm72ResultIndex">
                  <b-col sm="12" :md="d_divide" style="margin-bottom: 5px; padding-right: 5px; padding-left: 5px;">
                    <b-card :key="'wdm72_result_p_mode_else_' + wdm72_result_ind.toString()" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px; border: solid 2px #00bcd4;">
                      <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
                        <b-row style="margin: 0px;">
                          <b-col sm="12" md="8">
                            <strong>{{ wdm72_result.no }} -</strong>
                            <template v-if="wdm72_result.rc_ind !== undefined && wdm72_result.rc_ind !== ''">
                              {{ column_list[wdm72_result.rc_ind].label }} ({{ wdm72_result.rc_ind + 1 }}) & {{ column_list[wdm72_result.cc_ind].label }} ({{ wdm72_result.cc_ind + 1 }})
                            </template>
                            <template v-else-if="wdm72_result.col_index !== undefined && wdm72_result.col_index !== ''">
                              {{ column_list[wdm72_result.col_index].label }}
                            </template>
                            <template v-else-if="wdm72_result.rc_list !== undefined">
                              <template v-for="(rc_list_col, rc_list_col_ind) in wdm72_result.rc_list">
                                {{ column_list[rc_list_col].label }},
                              </template> =>
                              <template v-for="(cc_list_col, cc_list_col_ind) in wdm72_result.cc_list">
                                {{ column_list[cc_list_col].label }},
                              </template>
                            </template>
                            <template v-else-if="wdm72_result.multi_dim !== undefined">
                              <template v-for="rc_list_col_ind in wdm72_result.multi_dim.rc_col_order">
                                {{ column_list[rc_list_col_ind].label }},
                              </template> =>
                              <template v-for="cc_list_col_ind in wdm72_result.multi_dim.cc_col_order">
                                {{ column_list[cc_list_col_ind].label }},
                              </template>
                            </template>
                            <template v-else>
                              {{ wdm72_result.analyze_type }}
                            </template>
                            <b-badge variant="warning" style="margin-right: 5px;">{{ wdm72_result.analyze_type }}</b-badge>
                          </b-col>
                          <b-col sm="12" md="4" @click="f_openCloseThisResultIndex(wdm72_result_ind)">
                            <b-dropdown variant="dark" size="sm" class="pull-right">
                              <b-dropdown-item v-if="wdm72_result.analyze_type ==='before_after_comparison'" @click="f_showChartView('two_p1_and_number_val_list', wdm72_result.parameters)"> Grafik </b-dropdown-item>
                              <b-dropdown-item v-if="wdm72_result.analyze_type ==='frequency'" @click="f_showChartView('val_frequency_data', wdm72_result)"> Grafik </b-dropdown-item>
                              <b-dropdown-item v-if="wdm72_result.analyze_type ==='frequency'" @click="f_convertSelectedTableToExcel(wdm72_result.frequency_table)"> Excel olarak indir </b-dropdown-item>
                              <b-dropdown-item v-if="wdm72_result.analyze_type ==='cross_columns'" @click="f_showChartView('cross_row_column_data_list', wdm72_result.parameters)"> Grafik </b-dropdown-item>
                              <b-dropdown-item v-if="wdm72_result.analyze_type ==='cross_columns'" @click="f_convertSelectedTableToExcel(wdm72_result.table)"> Excel olarak indir </b-dropdown-item>
                            </b-dropdown>
                            <b-button size="md" variant="white" style="margin-right: 5px; padding: 0px;">
                              <img src="@/icon/824448.png" title="Aç - Kapa" style="width: 2em;" />
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-card-header>
                      <b-row v-if="wdm72_result.stats && Object.keys(wdm72_result.stats).length > 0" style="margin: 0px;">
                        <b-col cols=12>
                          <strong>Testler:</strong>
                          <template>
                            <template v-for="(stat_test, stat_test_ind) in wdm72_result.stats">
                              <span style="margin: 5px;">{{ stat_test_ind }}</span>
                            </template>
                          </template>
                        </b-col>
                      </b-row>
                      <b-row style="margin: 0px;">
                        <b-col cols=12>
                          <strong>ID:</strong> {{ wdm72_result.key }}
                        </b-col>
                      </b-row>
                      <template v-if="d_showResultIndexList.indexOf(wdm72_result_ind) !== -1">
                        <template v-if="d_showMode.show_stars">
                          <b-row>
                            <b-col sm="12" md="12" style="text-align: right;">
                              <template v-for="x in 10">
                                <b-button size="md" variant="white" style="padding: 0px; margin: 0px;" @click="f_scoreThisWdm72Result(x, wdm72_result, wdm72_result_ind)">
                                  <img v-if="x <= f_lookForUserScore(wdm72_result)" src="@/icon/3367414.png" style="width: 3em;" />
                                  <img v-else src="@/icon/3367414_3.png" style="width: 3em;" />
                                </b-button>
                              </template>
                            </b-col>
                          </b-row>
                          <template v-if="wdm72_result.score && wdm72_result.score.length > 0">
                            <template v-for="(score, score_ind) in wdm72_result.score">
                              <b-row v-if="score.username !== user.username">
                                <b-col sm="12" md="4"></b-col>
                                <b-col sm="12" md="4">
                                  {{ score.first_name + ' ' + score.last_name }}
                                </b-col>
                                <b-col sm="12" md="4">
                                  <template v-for="x in 10">
                                    <img v-if="x <= score.score" src="@/icon/3367414.png" style="width: 2em;" />
                                    <img v-else src="@/icon/3367414_3.png" style="width: 2em;" />
                                  </template>
                                </b-col>
                              </b-row>
                            </template>
                          </template>
                        </template>
                        <template v-if="wdm72_result.stats && Object.keys(wdm72_result.stats).length > 0">
                          <b-card no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
                            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                              <b-row>
                                <b-col cols="6">
                                  İstatistikler
                                </b-col>
                                <b-col cols="6" style="text-align: right;"></b-col>
                              </b-row>
                            </b-card-header>
                            <b-row>
                              <b-col cols="12">
                                <template v-for="(test, test_ind) in wdm72_result.stats">
                                  <template v-if="['logistic_regression', 'linear_regression'].indexOf(test_ind) !== -1">
                                    <table style="margin: 10px; margin-bottom: 3px;">
                                      <thead>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <th style="border: solid 1px #c3d8d8; min-width: 150px;"></th>
                                          <th style="border: solid 1px #c3d8d8; min-width: 150px;"> p değeri </th>
                                          <th style="border: solid 1px #c3d8d8; min-width: 150px;"> katsayı </th>
                                          <th style="border: solid 1px #c3d8d8; min-width: 150px;"> t değeri </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <template v-for="x in test.pvalues.length">
                                          <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                            <td style="border: solid 1px #c3d8d8; min-width: 150px;"><strong> {{ test.pvalues[x - 1][0] }} </strong></td>
                                            <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                              <template v-if="test.pvalues[x - 1][0] !== 'const'">
                                                <span :style="parseFloat(test.pvalues[x - 1][1]) <= 0.05 ? 'color: green; font-weight: bold;' :'color: black'">
                                    {{ parseFloat(test.pvalues[x - 1][1]).toFixed(3) }}
                                  </span>
                                              </template>
                                            </td>
                                            <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                              <span>
                                  {{ parseFloat(test.params[x - 1][1]).toFixed(3) }}
                                </span>
                                            </td>
                                            <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                              <span>
                                  {{ parseFloat(test.tvalues[x - 1][1]).toFixed(3) }}
                                </span>
                                            </td>
                                          </tr>
                                        </template>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-if="['chisquare'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;"><strong> ChiSquare Test => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> p : </b>
                                            <span v-if="test.p_value !== undefined" :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test.p_value }} </span>
                                          </td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> Chi-Square : </b> {{ test.chi_squared_stat }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['fisherexact'].indexOf(test_ind) !== -1">
                                    <table style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;"><strong> Fisher Exact Test => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b>{{ "Two-Sided" }}:</b>
                                            <template v-if="test['two-sided']">
                                              <br>
                                              <b> p : </b>
                                              <span v-if="test['two-sided'].p_value !== undefined" :style="parseFloat(test['two-sided'].p_value) <= parseFloat(test['two-sided'].p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test['two-sided'].p_value }} </span>
                                              <br>
                                              <b> odds ratio : </b> {{ test['two-sided'].oddsratio }}
                                            </template>
                                          </td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b>{{ "less" }}:</b>
                                            <template v-if="test['less']">
                                              <br>
                                              <b> p : </b>
                                              <span v-if="test['less'].p_value !== undefined" :style="parseFloat(test['less'].p_value) <= parseFloat(test['less'].p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test['less'].p_value }} </span>
                                              <br>
                                              <b> odds ratio : </b> {{ test['less'].oddsratio }}
                                            </template>
                                          </td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b>{{ "Greater" }}:</b>
                                            <template v-if="test['greater']">
                                              <br>
                                              <b> p : </b>
                                              <span v-if="test['greater'].p_value !== undefined" :style="parseFloat(test['greater'].p_value) <= parseFloat(test['greater'].p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test['greater'].p_value }} </span>
                                              <br>
                                              <b> odds ratio : </b> {{ test['greater'].oddsratio }}
                                            </template>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['oneway_anova'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;"><strong> OneWay Anova => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> p : </b>
                                            <span v-if="test.p_value !== undefined" :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test.p_value }} </span>
                                          </td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> F : </b> {{ test.f_stat }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['t_test'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;"><strong> t test => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> p : </b>
                                            <span v-if="test.p_value !== undefined" :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test.p_value }} </span>
                                          </td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> İstatistiksel Değer : </b> {{ test.statistic }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['mann_whitney_u'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;"><strong> Mann Whitney U test => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> p : </b>
                                            <span v-if="test.p_value !== undefined" :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test.p_value }} </span>
                                          </td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> İstatistiksel Değer : </b> {{ test.statistic }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['kruskal_wallis'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;"><strong> Kruskal Wallis test => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> p : </b>
                                            <span v-if="test.p_value !== undefined" :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test.p_value }} </span>
                                          </td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> İstatistiksel Değer : </b> {{ test.statistic }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['pearson_correlation'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;"><strong> Pearson Korelasyon => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> p : </b>
                                            <span v-if="test.p_value !== undefined" :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test.p_value }} </span>
                                          </td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> Güç Faktörü (Coefficient) : </b> {{ test.coefficient }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['spearman'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;"><strong> Spearman Korelasyon => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> p : </b>
                                            <span v-if="test.p_value !== undefined" :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test.p_value }} </span>
                                          </td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> Güç Faktörü (Coefficient) : </b> {{ test.coefficient }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['outliers_zindex'].indexOf(test_ind) !== -1">
                                    <table style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;" rowspan="2"><strong> aykırı değer saptama (outliers) z-index => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b-row>
                                              <b-col sm="6">
                                                Alt Sınır Altında Kalan Değerler ( alt sınır: {{ test.lower_bound }} )
                                              </b-col>
                                              <b-col sm="6">
                                                {{ test.outliers_lower.join(',') }}
                                              </b-col>
                                            </b-row>
                                          </td>
                                        </tr>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b-row>
                                              <b-col sm="6">
                                                Üst Sınır Üstünde Kalan Değerler ( üst sınır: {{ test.upper_bound }} )
                                              </b-col>
                                              <b-col sm="6">
                                                {{ test.outliers_upper.join(',') }}
                                              </b-col>
                                            </b-row>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['outliers_interquartile_range'].indexOf(test_ind) !== -1">
                                    <table style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;" rowspan="2"><strong> aykırı değer saptama (outliers) interquartile range => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b-row>
                                              <b-col sm="6">
                                                Alt Sınır Altında Kalan Değerler ( alt sınır: {{ test.lower_bound }} )
                                              </b-col>
                                              <b-col sm="6">
                                                {{ test.outliers_lower.join(',') }}
                                              </b-col>
                                            </b-row>
                                          </td>
                                        </tr>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b-row>
                                              <b-col sm="6">
                                                Üst Sınır Üstünde Kalan Değerler ( üst sınır: {{ test.upper_bound }} )
                                              </b-col>
                                              <b-col sm="6">
                                                {{ test.outliers_upper.join(',') }}
                                              </b-col>
                                            </b-row>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['kolmogorov_smirnov'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px black;">
                                          <td style="border: solid 1px #c3d8d8; background-color: #faffc6;"> {{ column_list[wdm72_result.col_index].type ==='wdm' ? column_list[wdm72_result.col_index].loc[column_list[wdm72_result.col_index].loc.length - 1].label : column_list[wdm72_result.col_index].label }} </td>
                                          <td style="border: solid 1px #c3d8d8; background-color: #faffc6;">
                                            <b> p : </b>
                                            <span v-if="test.p_value !== undefined" :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test.p_value }} </span>
                                            <b> İstatistiksel Değer : </b> {{ test.statistic }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['shapiro_wilk'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px black;">
                                          <td style="border: solid 1px #c3d8d8; background-color: #faffc6;"> {{ column_list[wdm72_result.col_index].type ==='wdm' ? column_list[wdm72_result.col_index].loc[column_list[wdm72_result.col_index].loc.length - 1].label : column_list[wdm72_result.col_index].label }} </td>
                                          <td style="border: solid 1px #c3d8d8; background-color: #faffc6;">
                                            <b> p : </b>
                                            <template v-if="test.p_value !== undefined">
                                              <span :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test.p_value }} </span>
                                              <b> İstatistiksel Değer : </b> {{ test.statistic }}
                                            </template>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['skewness'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <thead>
                                        <th style="white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white;">parametre</th>
                                        <th style="white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white;">Skewness</th>
                                        <th style="white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white;">p değeri</th>
                                      </thead>
                                      <tbody>
                                        <tr style="border: solid 1px black;">
                                          <td style="border: solid 1px #c3d8d8; background-color: #faffc6;"> {{ column_list[wdm72_result.col_index].type ==='wdm' ? column_list[wdm72_result.col_index].loc[column_list[wdm72_result.col_index].loc.length - 1].label : column_list[wdm72_result.col_index].label }} </td>
                                          <td style="border: solid 1px #c3d8d8; background-color: #faffc6;"> {{ test.statistic }} </td>
                                          <td style="border: solid 1px #c3d8d8; background-color: #faffc6;">
                                            <template v-if="test.p_value !== undefined">
                                              <span :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'">
                                          {{ test.p_value }}
                                        </span>
                                              <strong v-if="parseFloat(test.p_value) < 0.001"> ( p < 0.001 ) </strong>
                                            </template>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-else-if="['kurtosis'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <thead>
                                        <th style="white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white;">parametre</th>
                                        <th style="white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white;">kurtosis</th>
                                        <th style="white-space: nowrap; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white;">p değeri</th>
                                      </thead>
                                      <tbody>
                                        <tr style="border: solid 1px black;">
                                          <td style="border: solid 1px #c3d8d8; background-color: #faffc6;"> {{ column_list[wdm72_result.col_index].type ==='wdm' ? column_list[wdm72_result.col_index].loc[column_list[wdm72_result.col_index].loc.length - 1].label : column_list[wdm72_result.col_index].label }} </td>
                                          <td style="border: solid 1px #c3d8d8; background-color: #faffc6;"> {{ test.statistic }} </td>
                                          <td style="border: solid 1px #c3d8d8; background-color: #faffc6;">
                                            <template v-if="test.p_value !== undefined">
                                              <span :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'">
                                          {{ test.p_value }}
                                        </span>
                                              <strong v-if="parseFloat(test.p_value) < 0.001"> ( p < 0.001 ) </strong>
                                            </template>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                  <template v-if="['ttest_relation_paired_samples'].indexOf(test_ind) !== -1">
                                    <table v-if="test.p_value !== undefined" style="margin: 10px; margin-bottom: 3px;">
                                      <tbody>
                                        <tr style="border: solid 1px #c3d8d8; background-color: white;">
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;"><strong> Bağımlı Değişkenler, (Pairs Samples) t Test => </strong></td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> p : </b>
                                            <template v-if="test.p_value !== undefined">
                                              <span :style="parseFloat(test.p_value) <= parseFloat(test.p_value_sign) ?'color: green; font-weight: bold;' :'color: black'"> {{ test.p_value }} </span>
                                              <span v-if="parseFloat(test.p_value) < 0.001"> ( p < 0.001 ) </span>
                                            </template>
                                          </td>
                                          <td style="border: solid 1px #c3d8d8; min-width: 150px;">
                                            <b> İstatistiksel değer : </b> {{ test.statistics }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                </template>
                              </b-col>
                            </b-row>
                          </b-card>
                        </template>
                        <template v-if="wdm72_result.table_data && d_showMode.show_table">
                          <b-card v-for="(table_type, table_type_ind) in wdm72_result.table_data" :key="'wdm72_result_p_mode_else_table_data_' + wdm72_result_ind.toString() + '_' + table_type_ind.toString()" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
                            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                              <b-row>
                                <b-col cols="6">
                                  Tanımlayıcı İstatistikler - {{ table_type_ind }}
                                </b-col>
                                <b-col cols="6" style="text-align: right">
                                  <b-dropdown variant="success" size="sm">
                                    <b-dropdown-item @click="f_convertSelectedTableToExcel(table_type.table)"> Excel olarak indir </b-dropdown-item>
                                    <b-dropdown-item @click="f_showTableFullScreen(table_type.table,'Detaylı Tanımlayıcı İstatistikler')">{{ $t('wdm16.6589') }}</b-dropdown-item>
                                    <b-dropdown-item @click="f_addTableToArticle(table_type.table, wdm72_result.no, 'Tanımlayıcı İstatistikler')"> makaleye aktar </b-dropdown-item>
                                  </b-dropdown>
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <div style="overflow-x: auto;">
                              <table style="margin: 10px;">
                                <tbody>
                                  <template v-for="(r, r_index) in table_type.table">
                                    <tr style="border: solid 1px black;">
                                      <td v-for="(c, c_index) in r" style="min-width: 100px; text-align: center; border: solid 1px #c3d8d8; background-color: #faffc6;" @click="">
                                        <template v-if="c.toString().length > 30">
                                          {{ c.toString().substring(0, 30) }} <i style="color: green; cursor: pointer;" @click="f_showTableCellFullText(c)" :title="c.toString()">...</i>
                                        </template>
                                        <template v-else>
                                          {{ c }}
                                        </template>
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                          </b-card>
                        </template>
                        <template v-if="d_showMode.general_stats && ['skewness', 'kurtosis', 'kolmogorov_smirnov', 'shapiro_wilk', 'outliers_interquartile_range','outliers_zindex','mean','median','frequency','sum','min','max'].indexOf(wdm72_result.analyze_type) !== -1">
                          <b-card no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
                            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                              <b-row>
                                <b-col cols="6">
                                  Genel Bilgiler
                                </b-col>
                                <b-col cols="6" style="text-align: right"></b-col>
                              </b-row>
                            </b-card-header>
                            <table style="margin: 10px; width: 70%;">
                              <tbody>
                                <tr style="border: solid 1px black;">
                                  <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;"> toplam satır sayısı </td>
                                  <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;">{{ wdm72_result.case_count }}</td>
                                </tr>
                                <tr style="border: solid 1px black;">
                                  <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;"> boş satır sayısı (missing) </td>
                                  <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;">{{ wdm72_result.missing }}</td>
                                </tr>
                                <tr style="border: solid 1px black;">
                                  <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;"> dolu satır sayısı (valid) </td>
                                  <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;">{{ wdm72_result.valid }}</td>
                                </tr>
                                <tr style="border: solid 1px black;" v-if="['skewness', 'kurtosis', 'kolmogorov_smirnov', 'shapiro_wilk', 'outliers_interquartile_range','outliers_zindex','mean','median'].indexOf(wdm72_result.analyze_type) !== -1">
                                  <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;"> min-max </td>
                                  <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;">{{ wdm72_result.min }} - {{ wdm72_result.max }}</td>
                                </tr>
                                <tr style="border: solid 1px black;">
                                  <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;">dolu değerler (valid) </td>
                                  <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;">{{ wdm72_result.valid }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <template v-if="['mean','median','sum','min','max'].indexOf(wdm72_result.analyze_type) !== -1">
                              <table style="margin: 10px; width: 70%;">
                                <thead>
                                  <th style="white-space: nowrap; text-align: center; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white;">değişken</th>
                                  <th style="white-space: nowrap; text-align: center; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white;">{{ wdm72_result.analyze_type }}</th>
                                </thead>
                                <tbody>
                                  <tr style="border: solid 1px black;">
                                    <td style="text-align: center; border: solid 1px #c3d8d8; background-color: #faffc6;"> {{ column_list[wdm72_result.col_index].type ==='wdm' ? column_list[wdm72_result.col_index].loc[column_list[wdm72_result.col_index].loc.length - 1].label : column_list[wdm72_result.col_index].label }} </td>
                                    <td style="text-align: center; border: solid 1px #c3d8d8; background-color: #faffc6;"> {{ wdm72_result[wdm72_result.analyze_type] }} </td>
                                  </tr>
                                </tbody>
                              </table>
                              <b-card v-if="wdm72_result.val_list && wdm72_result.val_list.length > 0" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
                                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                                  <b-row>
                                    <b-col cols="6">
                                      Grafik
                                    </b-col>
                                    <b-col cols="6" style="text-align: right">
                                      <b-dropdown variant="success" size="sm">
                                        <b-dropdown-item @click="f_createChartData(wdm72_result.val_list, column_list[wdm72_result.col_index].label,'show_modal')"> Grafik </b-dropdown-item>
                                      </b-dropdown>
                                    </b-col>
                                  </b-row>
                                </b-card-header>
                                <div style="position: relative; height:250px; width:500px;">
                                  <line-view :chart_data="f_createChartData(wdm72_result.val_list, column_list[wdm72_result.col_index].label)" :height="250" :width="500" />
                                </div>
                              </b-card>
                            </template>
                          </b-card>
                        </template>
                        <template v-if="d_showMode.show_table && wdm72_result.frequency_table && wdm72_result.frequency_table.length > 0">
                          <b-card :key="'wdm72_result_p_mode_else_frequency_table_' + wdm72_result_ind.toString()" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
                            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                              <b-row>
                                <b-col cols="6">
                                  Sıklık Tablosu
                                </b-col>
                                <b-col cols="6" style="text-align: right">
                                  <b-dropdown variant="success" size="sm">
                                    <b-dropdown-item @click="f_convertSelectedTableToExcel(wdm72_result.frequency_table)"> Excel olarak indir </b-dropdown-item>
                                    <b-dropdown-item @click="f_showChartViewFromTable(wdm72_result.frequency_table,'bar', true, true)"> Grafik </b-dropdown-item>
                                    <b-dropdown-item @click="f_addTableToArticle(wdm72_result.frequency_table, wdm72_result.no, f_getAnalyzeName(wdm72_result))"> Tabloyu Makaleye Aktar </b-dropdown-item>
                                  </b-dropdown>
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <div style="overflow-x: auto;">
                              <table style="margin: 10px;">
                                <tbody>
                                  <template v-for="(r, r_index) in wdm72_result.frequency_table">
                                    <tr style="border: solid 1px black;">
                                      <td v-for="(c, c_index) in r" style="text-align: center; border: solid 1px #c3d8d8; background-color: #faffc6;">
                                        {{ c }}
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                          </b-card>
                        </template>
                        <b-card v-if="wdm72_result.timeline_mode && wdm72_result.timeline_mode.table && wdm72_result.timeline_mode.table.length > 0" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
                          <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                            <b-row>
                              <b-col cols="6">
                                Zaman Çizelgesi Modu Tablosu
                              </b-col>
                              <b-col cols="6" style="text-align: right">
                                <b-dropdown variant="success" size="sm">
                                  <b-dropdown-item @click="f_convertSelectedTableToExcel(wdm72_result.timeline_mode.table)"> Excel olarak indir </b-dropdown-item>
                                  <!-- <b-dropdown-item @click="f_showChartView('cross_row_column_data_list', wdm72_result.timeline_mode.analyze)"> Grafik </b-dropdown-item> -->
                                  <b-dropdown-item @click="f_showChartViewFromTable(wdm72_result.timeline_mode.table,'bar', true, false)"> Grafik </b-dropdown-item>
                                </b-dropdown>
                              </b-col>
                            </b-row>
                          </b-card-header>
                          <div style="overflow-x: auto; max-height: 300px;">
                            <table style="margin: 10px;">
                              <tbody>
                                <template v-for="(r, r_index) in wdm72_result.timeline_mode.table">
                                  <tr style="border: solid 1px black;">
                                    <td v-for="(c, c_index) in r" style="text-align: center; border: solid 1px #c3d8d8; background-color: #faffc6;">
                                      {{ c }}
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </b-card>
                        <b-card v-if="d_showMode.show_charts && wdm72_result.normalization" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
                          <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                            <b-row>
                              <b-col cols="6">
                                Normalizasyon Grafiği
                              </b-col>
                              <b-col cols="6" style="text-align: right;">
                                <b-dropdown variant="success" size="sm">
                                  <b-dropdown-item @click="f_createChartDataForCorrelation(wdm72_result,'show_modal')"> Grafik </b-dropdown-item>
                                </b-dropdown>
                              </b-col>
                            </b-row>
                          </b-card-header>
                          <div style="position: relative; height:250px; width:500px; overflow-y: auto;">
                            <line-view :chart_data="f_createChartDataForCorrelation(wdm72_result)" :height="250" :width="500" />
                          </div>
                        </b-card>
                        <template v-if="d_showMode.show_table && wdm72_result.table && wdm72_result.table.length > 0">
                          <div style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
                            <wisdom-table :key="'wdm72_result_table_' + wdm72_result_ind.toString()" :table_data="{'table': wdm72_result.table, 'name': ' Veri Tablosu'}" :mother_children="mother_children" :wdm72_result_data="{'analyze_no': wdm72_result.no, 'wdm72_result_ind': wdm72_result_ind}"></wisdom-table>
                          </div>
                        </template>
                        <template v-if="d_showMode.show_table && wdm72_result.table_list && wdm72_result.table_list.length > 0">
                          <div v-for="(tb, tb_index) in wdm72_result.table_list" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
                            <wisdom-table :key="'table_list_wdm72_result_table_' + wdm72_result_ind.toString()" :wdm72_result_data="wdm72_result" :table_data="{'table': tb.table, 'label': tb.label, 'type': tb.type}" :mother_children="mother_children"></wisdom-table>
                          </div>
                        </template>
                        <!-- chart list -->
                        <template v-if="d_showMode.show_charts && wdm72_result.charts && wdm72_result.charts.length > 0">
                          <b-card v-for="(chart, chart_index) in wdm72_result.charts" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;" :key="'chart_list_' + chart_index.toString() + '_' + wdm72_result_ind.toString()">
                            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                              <b-row>
                                <b-col cols="6">
                                  Grafik
                                </b-col>
                                <b-col cols="6" style="text-align: right">
                                  <b-dropdown variant="success" size="sm">
                                    <b-dropdown-item @click="f_showChartData(chart)"> Grafik </b-dropdown-item>
                                    <b-dropdown-item @click="f_addChartToArticleAsFigure(chart, wdm72_result.no, f_getAnalyzeName(wdm72_result))"> makaleye aktar </b-dropdown-item>
                                  </b-dropdown>
                                </b-col>
                              </b-row>
                            </b-card-header>
                            <div :style="f_calculateChartStyle(chart)">
                              <template v-if="!chart.type || chart.type ==='line'">
                                <line-view :chart_data="chart" :height="f_calculateChartHeight(chart)" :width="f_calculateChartWidth(chart)" />
                              </template>
                              <template v-if="chart.type ==='scatter'">
                                <scatter-view :chart_data="chart" :height="f_calculateChartHeight(chart)" :width="f_calculateChartWidth(chart)" />
                              </template>
                              <template v-if="chart.type ==='bar'">
                                <bar-view :chart_data="chart" :height="f_calculateChartHeight(chart)" :width="f_calculateChartWidth(chart)" />
                              </template>
                            </div>
                          </b-card>
                        </template>
                        <template v-if="wdm72_result.machine_learning && wdm72_result.machine_learning.length > 0">
                          <machine-learning-result :p_machineLearningResult="[wdm72_result]" :column_list="column_list"></machine-learning-result>
                        </template>
                        <template v-if="d_showMode.show_text && wdm72_result.text_list && wdm72_result.text_list.length > 0">
                          <b-card :key="'wdm72_result_p_mode_else_text_list_' + wdm72_result_ind.toString()" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
                            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                              Makale & rapor otomatik cümleler
                              <b-dropdown variant="success" size="sm" class="pull-right">
                                <b-dropdown-item @click="f_showArticleList(wdm72_result)"> Makaleye Ekle </b-dropdown-item>
                              </b-dropdown>
                            </b-card-header>
                            <b-row v-for="(text, text_ind) in wdm72_result.text_list" :key="'wdm72_result_p_mode_else_text_list_text_' + wdm72_result_ind.toString() + '_' + text_ind.toString()" style="margin: 5px; border-bottom: solid 1px #6f958a;" :title="text.text">
                              <b-col cols="12">
                                <strong v-if="text.lang" style="color: #007d66;">{{ text.lang }} : </strong>
                                <span v-if="text.text.length > 100" style="cursor: pointer;" @click="f_showTableCellFullText(text.text)"> {{ text.text.substring(0, 100) + '...' }} </span>
                                <span v-else> {{ text.text }} </span>
                              </b-col>
                            </b-row>
                          </b-card>
                        </template>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <template v-if="d_tableFullScreenData.show">
      <!-- ***************************** TABLE FULL SCREEN MODE ***********************************-->
      <b-card no-body header-tag="header" class="full-screen-mode">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
          <b-row>
            <b-col cols="6">
              {{ d_tableFullScreenData.label }}
            </b-col>
            <b-col cols="6" style="text-align: right">
              <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="d_tableFullScreenData.show = false;">
                <img src="@/icon/1890926.png" title="kapat" style="width: 2em;" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <div style="overflow-x: auto; height: 100%;">
          <table style="margin: 10px;">
            <tbody>
              <template v-for="(r, r_index) in d_tableFullScreenData.table">
                <tr :style="r_index % 2 === 0 ?'border: solid 1px black; background-color: #f6fcf5; line-height: 30px;' :'border: solid 1px black; line-height: 30px'">
                  <template v-for="(c, c_index) in r">
                    <td :style="f_calculateTableTdStyle(r_index, c_index)">
                      <template v-if="c.toString().length > 30">
                        {{ c.toString().substring(0, 30) }} <i style="color: green; cursor: pointer;" @click="f_showTableCellFullText(c)" :title="c.toString()">...</i>
                      </template>
                      <template v-else>
                        {{ c }}
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-card>
    </template>
    <b-modal id="d_filterAnalyzeData_show" v-model="d_filterAnalyzeData.show" scrollable title="Analiz Sonuçlarını Filtreleme" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <b-row>
        <b-col sm="12" md="6">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Kolon Filtreleme
            </b-card-header>
            <b-row style="overflow-y: auto; height: 200px; margin: 0px;">
              <b-col sm="12">
                <b-form-checkbox-group stacked v-model="d_filterAnalyzeData.selected_columns">
                  <b-form-checkbox v-for="(col_index, col_index_no) in d_filterAnalyzeData.filter_selected_columns" :key="'d_filterAnalyzeData.filter_selected_columns_' + col_index_no" name="not_assigned" :value="col_index">
                    {{ column_list[col_index].label }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col sm="12" md="6">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Analiz Tipi Filtreleme
            </b-card-header>
            <b-row style="overflow-y: auto; height: 200px; margin: 0px;">
              <b-col sm="12">
                <b-form-checkbox-group stacked v-model="d_filterAnalyzeData.selected_analyze_types">
                  <b-form-checkbox v-for="(x, x_ind) in d_filterAnalyzeData.filter_analyze_types" :key="'d_filterAnalyzeData.filter_analyze_types_' + x_ind" :value="x.value">
                    {{ x.label }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              İstatistik Testi
            </b-card-header>
            <b-row style="margin: 0px;">
              <b-col sm="12">
                <b-form-checkbox-group stacked v-model="d_filterAnalyzeData.selected_stats">
                  <b-form-checkbox v-for="(x, x_ind) in d_filterAnalyzeData.filter_stats" :key="'d_filterAnalyzeData.filter_stats_' + x_ind" :value="x.value">
                    {{ x.label }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col sm="12" md="6">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              P Değeri
            </b-card-header>
            <b-row style="margin: 0px;">
              <b-col sm="12">
                <b-form-checkbox v-model="d_filterAnalyzeData.selected_p_value" v-for="(x, x_ind) in d_filterAnalyzeData.filter_p_value" :key="'d_filterAnalyzeData.filter_p_value_' + x_ind" :value="x">
                  < {{ x }} </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="d_filterAnalyzeData.user_score_list" sm="12" md="6">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Kullanıcı Skorları
            </b-card-header>
            <!--
              d_filterAnalyzeData.user_score_list = {
                  'wisdom': {
                      'score_list': {
                          10: {'count': 5, 'selection': 0}
                      }
                  }
              }
            -->
            <template v-for="(user, user_ind) in d_filterAnalyzeData.user_score_list">
              <b-row style="margin: 0px;">
                <b-col sm="12" lg="12">
                  {{ user_ind }}
                </b-col>
                <b-col sm="6" lg="12">
                  <template v-for="(s, s_ind) in user.score_list">
                    <b-form-checkbox v-model="s.selection" :value="1" :unchecked-value="0"> {{ s_ind }} -> {{ s.count }} adet </b-form-checkbox>
                  </template>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
        <b-col sm="12" md="6"></b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button @click="f_applyFilterAnalyzeData()">İşlemi gerçekleştir</b-button>
      </template>
    </b-modal>
    <modal v-if="d_showGraphicView" @close="d_showGraphicView = false" :width="'1500'">
      <h3 slot="header">Grafik ekranı</h3>
      <div slot="body">
        X: {{ chart_data.labels.length }},
        <select v-model="d_chartSize.chart_type" style="height: 30px; width: 100px;margin-right: 3px;">
          <option value="line">çizgi</option>
          <option value="bar">bar</option>
          <option value="scatter">nokta</option>
        </select>
        <select v-if="d_chartSize.y_mode ==='standart'" v-model="d_chartSize.x_mode" style="height: 30px; width: 100px;margin-right: 3px;">
          <option value="standart">x standart</option>
          <option value="x_ascending">x artan</option>
          <option value="x_descending">x azalan</option>
        </select>
        <select v-if="d_chartSize.x_mode ==='standart'" v-model="d_chartSize.y_mode" style="height: 30px; width: 100px;margin-right: 3px;">
          <option value="standart">y standart</option>
          <option value="y_ascending">y artan</option>
          <option value="y_descending">y azalan</option>
        </select>
        <select v-model="d_chartSize.x_interval_type" style="height: 30px; width: 100px;margin-right: 3px;">
          <option value="all">'x' tüm değerler</option>
          <option value="once_every_x_value">'x' her ... değerde bir</option>
        </select>
        <input v-if="d_chartSize.x_interval_type ==='once_every_x_value'" type="number" v-model="d_chartSize.x_interval" min="1" style="height: 30px; width: 100px;margin-right: 3px;" placeholder="x kaç değerde bir görülecek">
        <!--         <div class="chart-wrapper" style="height: 100%;">
          <line-view :chart_data="chart_data" style="height:300px;" :height="300" />
        </div>
 -->
        <div v-if="d_showChartView" class="chart-wrapper" style="height: 100%;">
          <template v-if="d_chartSize.chart_type ==='line'">
            <line-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
          </template>
          <template v-if="d_chartSize.chart_type ==='bar'">
            <bar-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
          </template>
          <template v-if="d_chartSize.chart_type ==='scatter'">
            <scatter-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
          </template>
        </div>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showGraphicView = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal id="d_articleList_show" v-model="d_articleList.show" scrollable title="Makale Seçimi" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <b-row>
        <b-col sm="12" md="12">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Yazılar
            </b-card-header>
            <b-row style="margin: 0px;">
              <b-col cols="12">
                <b-button variant="secondary" size="sm" @click="f_selectionControlOfTextListForArticle()">tümü seçim işlemi</b-button>
              </b-col>
            </b-row>
            <b-row :style="d_articleList.wdm72_result.length + d_articleList.other.length > 3 ? 'overflow-x: auto; overflow-y: auto; height: 250px; margin: 0px;' : 'margin: 0px;'">
              <b-col cols="12">
                <template v-for="(wdm72_res, wdm72_res_ind) in d_articleList.wdm72_result">
                  <template v-for="(text, text_ind) in wdm72_res.text_list">
                    <b-row :key="'wdm72_res_text_' + wdm72_res_ind.toString()" style="margin: 0px;">
                      <b-col sm="1">
                        <b-form-checkbox v-model="text.selected" :value="1" :unchecked-value="0"></b-form-checkbox>
                      </b-col>
                      <b-col sm="11">
                        <b-textarea v-model="text.text" style="width: 100%;"></b-textarea>
                      </b-col>
                    </b-row>
                  </template>
                </template>
                <template v-for="(other, other_ind) in d_articleList.other">
                  <template v-for="(text, text_ind) in other.text_list">
                    <b-row :key="'other_text_' + other_ind.toString()" style="margin: 0px;">
                      <b-col sm="1">
                        <b-form-checkbox v-model="text.selected" :value="1" :unchecked-value="0"></b-form-checkbox>
                      </b-col>
                      <b-col sm="11">
                        <b-textarea v-model="text.text" style="width: 100%;"></b-textarea>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="4">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Makale Seçimi Yapınız
            </b-card-header>
            <b-row style="overflow-y: auto; height: 300px; margin: 0px;">
              <b-col sm="12">
                <b-form-checkbox-group stacked v-model="d_articleList.selected_articles">
                  <b-form-checkbox v-for="(article, article_ind) in d_articleList.list" :key="'d_articleList.list_' + article_ind" :value="article.wdm73_key.val">
                    {{ article.wdm73_name.val }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col sm="12" md="8">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Makale Bölümü Seçiniz <small>seçilen tüm alanlara aynı bilgileri ekleyecektir.</small>
            </b-card-header>
            <b-row style="overflow-y: auto; height: 300px; margin: 0px;">
              <b-col sm="12">
                <wdm-parameters :data_type="d_wdmParameters.data_type" :search_in_first_parameter_list="d_wdmParameters.search_in_first_parameter_list" :option_data="d_wdmParameters.option_data" :selected_index_data="d_wdmParameters.selected_index_data" :show_parameter_features="d_wdmParameters.show_parameter_features" :d_isThisModelEditable="false" :param_change="d_wdmParameters.param_change" :selection="d_wdmParameters.selection"></wdm-parameters>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button @click="f_sendToArticle()">makaleye aktar</b-button>
      </template>
    </b-modal>
    <b-modal id="d_tableToArticle_show" v-model="d_tableToArticle.show" scrollable title="Makaleye Tablo Ekleme Ekranı" centered header-bg-variant="info" header-text-variant="dark" size="md">
      <b-row>
        <b-col sm="12" md="12">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Makale Seçimi Yapınız
            </b-card-header>
            <b-row style="overflow-y: auto; height: 300px; margin: 0px;">
              <b-col sm="12">
                <b-form-checkbox-group stacked v-model="d_tableToArticle.wdmr_key_list">
                  <b-form-checkbox v-for="(article, article_ind) in d_tableToArticle.article_list" :key="'d_tableToArticle.article_list_' + article_ind" :value="article.wdm73_key.val">
                    {{ article.wdm73_name.val }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button @click="f_addTableToWdmr()">makaleye aktar</b-button>
      </template>
    </b-modal>
    <b-modal id="d_tableToArticle_show" v-model="d_chartToArticleAsFigure.show" scrollable title="Makaleye Figür Ekleme Ekranı" centered header-bg-variant="info" header-text-variant="dark" size="md">
      <b-row>
        <b-col sm="12" md="12">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Makale Seçimi Yapınız
            </b-card-header>
            <b-row style="overflow-y: auto; height: 300px; margin: 0px;">
              <b-col sm="12">
                <b-form-checkbox-group stacked v-model="d_chartToArticleAsFigure.wdmr_key_list">
                  <b-form-checkbox v-for="(article, article_ind) in d_chartToArticleAsFigure.article_list" :key="'d_chartToArticleAsFigure.article_list_' + article_ind" :value="article.wdm73_key.val">
                    {{ article.wdm73_name.val }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button @click="f_addChartToWdmrAsFigure()">makaleye aktar</b-button>
      </template>
    </b-modal>
    <b-modal v-if="d_tableData.show" id="d_tableData_show" v-model="d_tableData.show" scrollable title="Tablo Detayları Ekranı" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <b-card v-for="(table_type, table_type_ind) in d_tableData.table_data" :key="'d_tableData.table_data_' + table_type_ind" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
          <b-row>
            <b-col cols="6">
              Tanımlayıcı İstatistikler - {{ table_type_ind }}
            </b-col>
            <b-col cols="6" style="text-align: right">
              <b-dropdown variant="success" size="sm">
                <b-dropdown-item @click="f_convertSelectedTableToExcel(table_type.table)"> Excel olarak indir </b-dropdown-item>
                <b-dropdown-item @click="f_showTableFullScreen(table_type.table,'Detaylı Tanımlayıcı İstatistikler')">{{ $t('wdm16.6589') }}</b-dropdown-item>
                <b-dropdown-item @click="f_addTableToArticle(table_type.table, wdm72_result.no, 'Tanımlayıcı İstatistikler')"> makaleye aktar </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-card-header>
        <div style="overflow-x: auto;">
          <table style="margin: 10px;">
            <tbody>
              <template v-for="(r, r_index) in table_type.table">
                <tr style="border: solid 1px black;">
                  <td v-for="(c, c_index) in r" style="min-width: 100px; text-align: center; border: solid 1px #c3d8d8; background-color: #faffc6;" @click="">
                    <template v-if="c.toString().length > 30">
                      {{ c.toString().substring(0, 30) }} <i style="color: green; cursor: pointer;" @click="f_showTableCellFullText(c)" :title="c.toString()">...</i>
                    </template>
                    <template v-else>
                      {{ c }}
                    </template>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-card>
      <template slot="modal-footer">
      </template>
    </b-modal>
    <b-modal v-if="d_selectedWdm72Result.show" id="d_selectedWdm72Result_show" v-model="d_selectedWdm72Result.show" scrollable title="Tablo Detayları Ekranı" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <b-card no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
          <b-row>
            <b-col cols="6">
              Genel Bilgiler
            </b-col>
            <b-col cols="6" style="text-align: right"></b-col>
          </b-row>
        </b-card-header>
        <table style="margin: 10px; width: 70%;">
          <tbody>
            <tr style="border: solid 1px black;">
              <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;"> toplam satır sayısı </td>
              <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;">{{ d_selectedWdm72Result.wdm72_result.case_count }}</td>
            </tr>
            <tr style="border: solid 1px black;">
              <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;"> boş satır sayısı (missing) </td>
              <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;">{{ d_selectedWdm72Result.wdm72_result.missing }}</td>
            </tr>
            <tr style="border: solid 1px black;">
              <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;"> dolu satır sayısı (valid) </td>
              <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;">{{ d_selectedWdm72Result.wdm72_result.valid }}</td>
            </tr>
            <tr style="border: solid 1px black;" v-if="['skewness', 'kurtosis', 'kolmogorov_smirnov', 'shapiro_wilk', 'outliers_interquartile_range','outliers_zindex','mean','median'].indexOf(d_selectedWdm72Result.wdm72_result.analyze_type) !== -1">
              <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;"> min-max </td>
              <td style="text-align: center; border: solid 1px #c3d8d8; background-color: white;">{{ d_selectedWdm72Result.wdm72_result.min }} - {{ d_selectedWdm72Result.wdm72_result.max }}</td>
            </tr>
          </tbody>
        </table>
        <template v-if="['mean','median','sum','min','max'].indexOf(d_selectedWdm72Result.wdm72_result.analyze_type) !== -1">
          <table style="margin: 10px; width: 70%;">
            <thead>
              <th style="white-space: nowrap; text-align: center; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white;">parametre</th>
              <th style="white-space: nowrap; text-align: center; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: white;">{{ d_selectedWdm72Result.wdm72_result.analyze_type }}</th>
            </thead>
            <tbody>
              <tr style="border: solid 1px black;">
                <td style="text-align: center; border: solid 1px #c3d8d8; background-color: #faffc6;"> {{ column_list[d_selectedWdm72Result.wdm72_result.col_index].type ==='wdm' ? column_list[d_selectedWdm72Result.wdm72_result.col_index].loc[column_list[d_selectedWdm72Result.wdm72_result.col_index].loc.length - 1].label : column_list[d_selectedWdm72Result.wdm72_result.col_index].label }} </td>
                <td style="text-align: center; border: solid 1px #c3d8d8; background-color: #faffc6;"> {{ d_selectedWdm72Result.wdm72_result[d_selectedWdm72Result.wdm72_result.analyze_type] }} </td>
              </tr>
            </tbody>
          </table>
          <b-card v-if="d_selectedWdm72Result.wdm72_result.val_list && d_selectedWdm72Result.wdm72_result.val_list.length > 0" no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
            <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
              <b-row>
                <b-col cols="6">
                  Grafik
                </b-col>
                <b-col cols="6" style="text-align: right">
                  <b-dropdown variant="success" size="sm">
                    <b-dropdown-item @click="f_createChartData(d_selectedWdm72Result.wdm72_result.val_list, column_list[d_selectedWdm72Result.wdm72_result.col_index].label,'show_modal')"> Grafik </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-card-header>
            <div style="position: relative; height:250px; width:500px;">
              <line-view :chart_data="f_createChartData(d_selectedWdm72Result.wdm72_result.val_list, column_list[d_selectedWdm72Result.wdm72_result.col_index].label)" :height="250" :width="500" />
            </div>
          </b-card>
        </template>
      </b-card>
      <template slot="modal-footer">
      </template>
    </b-modal>
    <b-modal v-if="d_frequencyTable.show" id="d_frequencyTable_show" v-model="d_frequencyTable.show" scrollable title="Tablo Detayları Ekranı" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <b-card no-body header-tag="header" style="margin-bottom: 3px; margin-left: 10px; margin-top: 3px;">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
          <b-row>
            <b-col cols="6">
              Sıklık Tablosu
            </b-col>
            <b-col cols="6" style="text-align: right">
              <b-dropdown variant="success" size="sm">
                <b-dropdown-item @click="f_convertSelectedTableToExcel(d_frequencyTable.frequency_table)"> Excel olarak indir </b-dropdown-item>
                <b-dropdown-item @click="f_showChartViewFromTable(d_frequencyTable.frequency_table,'bar', true, true)"> Grafik </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-card-header>
        <div style="overflow-x: auto;">
          <table style="margin: 10px;">
            <tbody>
              <template v-for="(r, r_index) in d_frequencyTable.frequency_table">
                <tr style="border: solid 1px black;">
                  <td v-for="(c, c_index) in r" style="text-align: center; border: solid 1px #c3d8d8; background-color: #faffc6;">
                    {{ c }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-card>
      <template slot="modal-footer">
      </template>
    </b-modal>
    <b-modal v-if="d_showTableCellFullText" id="d_showTableCellFullText" v-model="d_showTableCellFullText" scrollable hide-footer title="Hücre Tam Görünümü" centered header-bg-variant="info" header-text-variant="dark">
      <p class="my-4" style="word-wrap: break-word;">
        {{ d_selectedTableCellFullText }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import {
  default as WisdomTable
} from "@/components/widgets/WisdomTable";
import {
  default as MachineLearningResult
} from "@/components/widgets/MachineLearningResult";
import {
  default as WdmParameters
} from "@/components/widgets/WdmParameters";
import LineView from '@/components/chart/LineView';
import ScatterView from '@/components/chart/ScatterView';
import BarView from '@/components/chart/BarView';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WdmParameterLocation
} from '@/components/widgets/WdmParameterLocation';
import { mapGetters } from 'vuex';
import WdmService from '@/services/wdm';
import PoleStarService from '@/services/polestar';
import FiletransferService from '@/services/file_transfer';
import moment from 'moment';
import FileSaver from 'file-saver';
export default {
  name: 'PolestarResult',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang'
  }),
  components: {
    Modal,
    LineView,
    BarView,
    ScatterView,
    WdmParameterLocation,
    WdmParameters,
    WisdomTable,
    MachineLearningResult
  },
  props: {
    p_selectedWdm72Results: {
      type: Array,
      required: true
    },
    column_list: {
      type: Array,
      required: true
    },
    p_selectedProjectData: {
      type: Object,
      required: true
    },
    p_mode: {
      type: String,
      required: false,
    },
    d_selectedWdm73Data: {
      type: Object,
      required: false
    },
    option_data: {
      type: Object,
      required: false
    },
    p_showAnalyzeCharts: {
      type: Boolean,
      required: false
    },
    d_selectedWdm73Key: {
      type: String,
      required: false
    },
    d_selectedWdm72Key: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      d_showResultIndexList: [],
      d_wdm72Pagination: {
        'start': 0,
        'end': 50,
        'current': 1,
        'perpage': 50,
        'all_count': 0
      },
      d_filterAnalyzeData: {
        'user_score_list': '',
        'selected_columns': [],
        'filter_analyze_types': [],
        'filter_stats': [],
        'filter_p_value': ['', '0.001', '0.05', '0.06', '0.07', '0.08', '0.09', '0.1'],
        'selected_analyze_types': [],
        'selected_stats': [],
        'show': false,
        'selected_p_value': ''
      },
      d_fullScreenResults: false,
      d_divide: 12,
      d_showMode: {
        'show_stats': true,
        'show_stars': false,
        'show_charts': true,
        'show_text': false,
        'show_table': true,
        'general_stats': true
      },
      d_wdmParameters: {
        'selection': {
          'times': 'multi', // one, multi
          'type': 'this_index', // all, this_index, this_types
          'this_index': [
            ['article_summary', 'article_summary_purpose', 'article_summary_purpose_info'],
            ['article_summary', 'article_summary_methods', 'article_summary_methods_info'],
            ['article_summary', 'article_summary_results', 'article_summary_results_info'],
            ['article_summary', 'article_summary_conclusion', 'article_summary_conclusion_info'],
            ['article', 'article_general_information', 'article_general_information_info'],
            ['article', 'article_methods', 'article_methods_info'],
            ['article', 'article_statistics', 'article_statistics_info'],
            ['article', 'article_results', 'article_results_info'],
            ['article', 'article_conclusion', 'article_conclusion_info']
          ]
        },
        'brief_list': {},
        'selected_wdm': '',
        'user_selected_brief_wdm_list': [],
        'option_data': {},
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 }
      },
      mother_children: { 'forceUpdate': 0, 'send_table_to_article': 0 },
      d_tableFullScreenData: { 'show': false, 'table': [], 'label': '' },
      d_selectedTableCellFullText: '',
      d_showTableCellFullText: false,
      d_showChartView: true,
      d_chartSize: {
        'height': 0,
        'width': 0,
        'x_mode': 'standart',
        'y_mode': 'standart',
        'x_interval_type': 'all',
        'x_interval': 1,
        'chart_type': ''
      },
      d_showGraphicView: false,
      chart_data: {},
      d_resetChartData: {},
      user: {},
      d_articleList: {
        'show': false,
        'list': [],
        'selected_articles': [],
        'other': [],
        'wdm72_result': []
      },
      d_tableToArticle: {
        'show': false,
        'wdmr_key_list': [],
        'buck': '', // wisdom, polestar, ...
        'table': '', // table data contains multidimensional array. [[0,1,2,3], [4,5,6,7], ...]
        'wdm72_key': '',
        'selected_wdm73_key': '',
        'analyze_no': '',
        'table_name': '',
        'article_list': []
      },
      d_chartToArticleAsFigure: {
        'show': false,
        'wdmr_key_list': [],
        'buck': 'polestar', // wisdom, polestar, ...
        'chart': '',
        'wdm72_key': '',
        'chart_name': '',
        'analyze_no': '',
        'article_list': []
      },
      d_tableData: { 'show': false, 'table_data': {} },
      d_selectedWdm72Result: { 'show': false, 'wdm72_result': {} },
      d_selectedWdm72ResultIndex: '',
      d_frequencyTable: { 'show': false, 'wdm72_result': {} }
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  beforeMount () {},
  mounted: function () {
    if (this.d_selectedWdm72Key) {
      this.f_getWdm72AnalyzeList();
    }
    this.f_updateShowResultIndexList();
  },
  methods: {
    f_cancelWdm72ResultSelection: function () {
      this.d_selectedWdm72ResultIndex = '';
      this.$forceUpdate();
    },
    f_selectWdm72Result: function (analyze_index) {
      this.d_selectedWdm72ResultIndex = analyze_index;
      this.$forceUpdate();
    },
    f_calculateItemHeaderStyle: function (analyze_index = '') {
      let style = '';
      if (analyze_index === '') {
        style += 'border-bottom: 1px solid #989898; background-color: #daf0e1; cursor: pointer; min-height: 30px;';
      } else {
        if (this.d_selectedWdm72ResultIndex === analyze_index) {
          style += 'border-bottom: 1px solid #989898; background-color: #5cf28e; cursor: pointer; min-height: 30px;';
        } else {
          style += 'border-bottom: 1px solid #989898; background-color: #daf0e1; cursor: pointer; min-height: 30px;';
        }
      }
      return style;
    },
    f_openCloseAllResults: function () {
      if (this.d_showResultIndexList.length === 0) {
        this.f_updateShowResultIndexList();
      } else {
        this.d_showResultIndexList = [];
      }
      this.$forceUpdate();
    },
    f_openCloseThisResultIndex: function (result_ind) {
      if (this.d_showResultIndexList.indexOf(result_ind) === -1) {
        this.d_showResultIndexList.push(result_ind);
      } else {
        this.d_showResultIndexList.splice(this.d_showResultIndexList.indexOf(result_ind), 1);
      }
      this.$forceUpdate();
    },
    f_updateShowResultIndexList: function () {
      this.d_showResultIndexList = [];
      for (let i in this.p_selectedWdm72Results) {
        this.d_showResultIndexList.push(parseInt(i));
      }
    },
    f_applyFilterAnalyzeData: function () {
      this.d_filterAnalyzeData.show = false;
      this.d_wdm72Pagination = {
        'start': 0,
        'end': 50,
        'current': 1,
        'perpage': 50,
        'all_count': 0
      };
      this.f_getWdm72AnalyzeList();
    },
    f_getWdm72AnalyzeList: function () {
      this.d_selectedWdm72ResultIndex = '';
      this.p_selectedWdm72Results.splice(0, this.p_selectedWdm72Results.length);
      let analyze_data = {
        'pagination': this.d_wdm72Pagination,
        'wdm72_key': this.d_selectedWdm72Key,
        'selected_analyze_types': this.d_filterAnalyzeData.selected_analyze_types,
        'selected_p_value': this.d_filterAnalyzeData.selected_p_value,
        'selected_columns': this.d_filterAnalyzeData.selected_columns,
        'selected_stats': this.d_filterAnalyzeData.selected_stats,
        'user_score_list': this.d_filterAnalyzeData.user_score_list ? this.d_filterAnalyzeData.user_score_list : ''
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analizler getiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.get_analyze_data(analyze_data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            for (let i in resp.data.result.analyze_list) {
              this.p_selectedWdm72Results.push(resp.data.result.analyze_list[i]);
            }
            if (resp.data.result.selected_columns) {
              this.d_filterAnalyzeData.filter_selected_columns = resp.data.result.selected_columns;
            }
            /*
                user_score_list = {
                    'wisdom': {
                        'score_list': {
                            10: {'count': 5, 'selection': 0}
                        }
                    }
                }
            */
            // console.log(resp.data.result.user_score_list);
            if (resp.data.result.user_score_list) {
              if (this.d_filterAnalyzeData.user_score_list) {
                for (let u in resp.data.result.user_score_list) {
                  if (!this.d_filterAnalyzeData.user_score_list[u]) {
                    this.d_filterAnalyzeData.user_score_list[u] = resp.data.result.user_score_list[u];
                  } else {
                    for (let s in resp.data.result.user_score_list[u].score_list) {
                      if (!this.d_filterAnalyzeData.user_score_list[u].score_list[s]) {
                        this.d_filterAnalyzeData.user_score_list[u].score_list[s] = resp.data.result.user_score_list[u].score_list[s];
                      }
                    }
                  }
                }
              } else {
                this.d_filterAnalyzeData.user_score_list = resp.data.result.user_score_list;
              }
            }
            this.d_filterAnalyzeData.filter_stats = resp.data.result.filter_stats;
            this.d_filterAnalyzeData.filter_analyze_types = resp.data.result.filter_analyze_types;
            this.d_wdm72Pagination.all_count = resp.data.result.all_count;
            this.f_updateShowResultIndexList();
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_createPDFofWdm72Results: function (wdm72_key, type) {
      let data = {
        'wdm72_key': wdm72_key,
        'type': type // all, filtered
      };
      if (type === 'filtered') {
        data['selected_analyze_types'] = this.d_filterAnalyzeData.selected_analyze_types;
        data['selected_p_value'] = this.d_filterAnalyzeData.selected_p_value;
        data['selected_columns'] = this.d_filterAnalyzeData.selected_columns;
        data['selected_stats'] = this.d_filterAnalyzeData.selected_stats;
        data['user_score_list'] = this.d_filterAnalyzeData.user_score_list ? this.d_filterAnalyzeData.user_score_list : '';
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'PDF üretim işlemi başlatıldı. Hazırlandıkça gönderilecektir.  Lütfen bekleyiniz.' } });
      PoleStarService.create_pdf_of_wdm72_results(data)
        .then(resp => {
          // console.log(resp.data);
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            // let file_name = wdm72_key + ".pdf";
            // FileSaver.saveAs(resp.data.result.file, file_name);
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    },
    f_changeResultsDevide: function () {
      if (this.d_divide === 12) {
        this.d_divide = 6;
      } else if (this.d_divide === 6) {
        this.d_divide = 4;
      } else if (this.d_divide === 4) {
        this.d_divide = 12;
      }
      this.$forceUpdate();
    },
    f_calculateChartWidth: function (chart) {
      if (chart.labels) {
        if (chart.labels.length > 5 && chart.labels.length <= 10) {
          return 800;
        } else if (chart.labels.length > 10) {
          return 1000;
        } else {
          return 500;
        }
      } else {
        return 500;
      }
    },
    f_calculateChartHeight: function (chart) {
      if (chart.labels) {
        if (chart.labels.length > 5 && chart.labels.length <= 10) {
          return 400;
        } else if (chart.labels.length > 10) {
          return 500;
        } else {
          return 250;
        }
      } else {
        return 250;
      }
    },
    f_calculateChartStyle: function (chart) {
      let style = '';
      if (chart.labels) {
        if (chart.labels.length > 5 && chart.labels.length <= 10) {
          if (chart.datasets.length > 5 && chart.datasets.length <= 10) {
            return 'position: relative; height:600px; width:1200px;';
          } else if (chart.datasets.length > 10) {
            return 'position: relative; height:800px; width:1600px;';
          } else {
            return 'position: relative; height:400px; width:800px;';
          }
        } else if (chart.labels.length > 10) {
          if (chart.datasets.length > 5 && chart.datasets.length <= 10) {
            return 'position: relative; height:700px; width:1400px;';
          } else if (chart.datasets.length > 10) {
            return 'position: relative; height:900px; width:1800px;';
          } else {
            return 'position: relative; height:500px; width:1000px;';
          }
        } else {
          if (chart.datasets.length > 5 && chart.datasets.length <= 10) {
            return 'position: relative; height:450px; width:900px;';
          } else if (chart.datasets.length > 10) {
            return 'position: relative; height:650px; width:1300px;';
          } else {
            return 'position: relative; height:250px; width:500px;';
          }
        }
      } else {
        return 'position: relative; height:250px; width:500px;';
      }
    },
    f_lookForUserScore: function (wdm72_result) {
      if (wdm72_result.score && wdm72_result.score.length > 0) {
        for (let i in wdm72_result.score) {
          if (wdm72_result.score[i].username === this.user.username) {
            return wdm72_result.score[i].score;
          }
        }
      }
      return 0;
    },
    f_scoreThisWdm72Result: function (score, wdm72_result, wdm72_result_ind) {
      let data = {
        'score': score,
        'wdm72_key': this.d_selectedWdm72Key,
        'no': wdm72_result.no,
        'res_key': wdm72_result.key
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Skor verme işleminiz gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.score_this_wdm72_result(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.p_selectedWdm72Results[wdm72_result_ind].score = resp.data.result.score;
            this.$forceUpdate();
            // this.d_articleList.show = false;
            // alert('Makale aktarım işlemi tamamlandı.');
          } else {
            alert('Error :' + resp.data.message)
              // this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          }
        });
    },
    f_showFrequencyTable: function (frequency_table) {
      this.d_frequencyTable.frequency_table = frequency_table;
      this.d_frequencyTable.show = true;
    },
    f_generalInformationWdm72_result: function (wdm72_result) {
      this.d_selectedWdm72Result.wdm72_result = wdm72_result;
      this.d_selectedWdm72Result.show = true;
    },
    f_showTableData: function (table_data) {
      this.d_tableData.table_data = table_data;
      this.d_tableData.show = true;
    },
    f_getAnalyzeName: function (wdm72_result) {
      if (wdm72_result.col_index !== undefined) {
        return this.column_list[wdm72_result.col_index].label + ' (' + wdm72_result.analyze_type + ')';
      } else if (wdm72_result.rc_ind !== undefined) {
        return this.column_list[wdm72_result.rc_ind].label + ' & ' + this.column_list[wdm72_result.cc_ind].label + ' (' + wdm72_result.analyze_type + ')';
      } else if (wdm72_result.rc_list !== undefined) {
        let str_row = '';
        for (let k in wdm72_result.rc_list) {
          let x = wdm72_result.rc_list[k];
          str_row += this.column_list[x].label + ' '
        }
        let str_col = '';
        for (let k in wdm72_result.cc_list) {
          let x = wdm72_result.cc_list[k];
          str_col += this.column_list[x].label + ' '
        }
        return str_row + ' & ' + str_col + ' (' + wdm72_result.analyze_type + ')';
      }
    },
    f_addTableToArticle: function (table, analyze_no, table_name, table_type = '') {
      if (this.p_selectedProjectData.data.articles && this.p_selectedProjectData.data.articles.article && this.p_selectedProjectData.data.articles.article.list.length > 0) {
        this.d_tableToArticle = {
          'show': false,
          'wdmr_key_list': [],
          'buck': 'polestar', // wisdom, polestar, ...
          'table': table, // table data contains multidimensional array. [[0,1,2,3], [4,5,6,7], ...]
          'wdm72_key': this.d_selectedWdm72Key,
          'selected_wdm73_key': '',
          'analyze_no': analyze_no,
          'table_name': table_name,
          'type': table_type,
          'article_list': JSON.parse(JSON.stringify(this.p_selectedProjectData.data.articles.article.list))
        }
        this.d_tableToArticle.show = true;
      } else {
        alert('Henüz eklenmiş makale bulunmamaktadır.');
      }
    },
    f_addTableToWdmr: function () {
      let data = {
        'wdmr_key_list': this.d_tableToArticle.wdmr_key_list,
        'buck': this.d_tableToArticle.buck, // wisdom, polestar, ...
        'table': this.d_tableToArticle.table, // table data contains multidimensional array. [[0,1,2,3], [4,5,6,7], ...]
        'table_name': this.d_tableToArticle.table_name,
        'type': this.d_tableToArticle.type,
        'wdm72_key': this.d_tableToArticle.wdm72_key,
        'selected_wdm73_key': this.d_tableToArticle.selected_wdm73_key, // we get this, to return the wdmr data of this wdmr73, because it is selected
        'analyze_no': this.d_tableToArticle.analyze_no
      };
      if (this.d_tableToArticle.table_type) {
        data.type = this.d_tableToArticle.table_type;
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tablo aktarım işlemi başlatıldı. Lütfen bekleyiniz.' } });
      WdmService.add_table_to_wdmr(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedWdm73Data = resp.data.result['wdmr73_data'];
            alert('Tablo makaleye aktarıldı.');
            this.d_tableToArticle.show = false;
            this.$forceUpdate();
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    },
    f_addChartToArticleAsFigure: function (chart, analyze_no, chart_name) {
      let chart_data = {
        'backgroundColor': chart.backgroundColor ? chart.backgroundColor : '#fff',
        'name': chart.name ? chart.name : '',
        'datasets': [],
        'type': chart.type,
        'labels': chart.labels
      };
      for (let d in chart.datasets) {
        let new_dataset = {}
        if (chart.datasets[d].label !== undefined) {
          new_dataset.label = chart.datasets[d].label;
        }
        if (chart.datasets[d].lineTension !== undefined) {
          new_dataset.lineTension = chart.datasets[d].lineTension;
        }
        if (chart.datasets[d].borderColor !== undefined) {
          new_dataset.borderColor = chart.datasets[d].borderColor;
        }
        if (chart.datasets[d].backgroundColor !== undefined) {
          new_dataset.backgroundColor = chart.datasets[d].backgroundColor;
        }
        if (chart.datasets[d].showLine !== undefined) {
          new_dataset.showLine = chart.datasets[d].showLine;
        }
        if (chart.datasets[d].data !== undefined) {
          new_dataset.data = chart.datasets[d].data;
        }
        chart_data.datasets.push(new_dataset);
      }
      if (this.p_selectedProjectData.data.articles && this.p_selectedProjectData.data.articles.article && this.p_selectedProjectData.data.articles.article.list.length > 0) {
        this.d_chartToArticleAsFigure = {
          'show': false,
          'wdmr_key_list': [],
          'buck': 'polestar', // wisdom, polestar, ...
          'chart': chart_data, // chart_data
          'wdm72_key': this.d_selectedWdm72Key,
          'selected_wdm73_key': this.d_selectedWdm73Key,
          'analyze_no': analyze_no,
          'chart_name': chart_name,
          'article_list': JSON.parse(JSON.stringify(this.p_selectedProjectData.data.articles.article.list))
        }
        this.d_chartToArticleAsFigure.show = true;
      } else {
        alert('Henüz eklenmiş makale bulunmamaktadır.');
      }
    },
    f_addChartToWdmrAsFigure: function () {
      let data = {
        'wdmr_key_list': this.d_chartToArticleAsFigure.wdmr_key_list,
        'buck': this.d_chartToArticleAsFigure.buck, // wisdom, polestar, ...
        'chart': this.d_chartToArticleAsFigure.chart,
        'chart_name': this.d_chartToArticleAsFigure.chart_name,
        'wdm72_key': this.d_chartToArticleAsFigure.wdm72_key,
        'selected_wdm73_key': this.d_chartToArticleAsFigure.selected_wdm73_key, // we get this, to return the wdmr data of this wdmr73, because it is selected
        'analyze_no': this.d_chartToArticleAsFigure.analyze_no
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Grafik aktarım işlemi başlatıldı. Lütfen bekleyiniz.' } });
      WdmService.add_chart_to_wdmr_as_figure(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedWdm73Data = resp.data.result['wdmr73_data'];
            alert('Grafik makaleye aktarıldı.');
            this.d_chartToArticleAsFigure.show = false;
            this.$forceUpdate();
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    },
    f_selectionControlOfTextListForArticle: function () {
      let is_first_text_selected = false;
      for (let i in this.d_articleList.other) {
        for (let t in this.d_articleList.other[i].text_list) {
          if (this.d_articleList.other[i].text_list[t].selected) {
            is_first_text_selected = true;
            break;
          }
        }
        if (is_first_text_selected) {
          break;
        }
      }
      for (let i in this.d_articleList.wdm72_result) {
        for (let t in this.d_articleList.wdm72_result[i].text_list) {
          if (this.d_articleList.wdm72_result[i].text_list[t].selected) {
            is_first_text_selected = true;
            break;
          }
        }
        if (is_first_text_selected) {
          break;
        }
      }
      for (let i in this.d_articleList.other) {
        for (let t in this.d_articleList.other[i].text_list) {
          if (is_first_text_selected) {
            this.d_articleList.other[i].text_list[t].selected = 0;
          } else {
            this.d_articleList.other[i].text_list[t].selected = 1;
          }
        }
      }
      for (let i in this.d_articleList.wdm72_result) {
        for (let t in this.d_articleList.wdm72_result[i].text_list) {
          if (is_first_text_selected) {
            this.d_articleList.wdm72_result[i].text_list[t].selected = 0;
          } else {
            this.d_articleList.wdm72_result[i].text_list[t].selected = 1;
          }
        }
      }
      this.$forceUpdate();
    },
    f_showArticleList: function (wdm72_result, other_text_list = []) {
      if (this.p_selectedProjectData.data.articles && this.p_selectedProjectData.data.articles.article && this.p_selectedProjectData.data.articles.article.list && this.p_selectedProjectData.data.articles.article.list.length > 0) {
        this.d_articleList.list = JSON.parse(JSON.stringify(this.p_selectedProjectData.data.articles.article.list));
        this.d_articleList.show = true;
        this.d_articleList.other = [];
        this.d_articleList.wdm72_result = [];
        if (wdm72_result) {
          let wdm_result_data = { 'no': wdm72_result.no, 'text_list': wdm72_result.text_list };
          this.d_articleList.wdm72_result.push(wdm_result_data);
        }
        if (other_text_list.length > 0) {
          this.d_articleList.other = this.d_articleList.other.concat(other_text_list);
        }
        this.d_wdmParameters.option_data = { 'wdm73': this.option_data['wdm73'] };
        this.d_wdmParameters.data_type = 'wdm73';
      } else {
        alert('Henüz eklenmiş makale bulunmamaktadır.');
      }
    },
    f_sendToArticle: function () {
      if (this.d_articleList.selected_articles.length > 0) {
        if (this.d_wdmParameters.selected_index_data.index_list.length > 0) {
          let wdm_72_key = this.d_selectedWdm72Key;
          let data = {
            'other': this.d_articleList.other,
            'wdm72_key': this.d_selectedWdm72Key,
            'wdm72_result': this.d_articleList.wdm72_result,
            'selected_articles': this.d_articleList.selected_articles,
            'index_list': this.d_wdmParameters.selected_index_data.index_list
          };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Makaleye aktarım işlemi başlatıldı. Lütfen bekleyiniz.' } });
          PoleStarService.add_text_list_to_article(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                // this.d_articleList.show = false;
                alert('Makale aktarım işlemi tamamlandı.');
              } else {
                alert('Error :' + resp.data.message)
                this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
              }
            });
        } else {
          alert('Lütfen parametre seçimi yapınız');
        }
      } else {
        alert('Lütfen parametre seçimi yapınız');
      }
    },
    f_calculateTableTdStyle: function (r_index, c_index) {
      let style = 'min-width: 100px; text-align: center; border: solid 1px #c3d8d8;';
      if (r_index === 0 || c_index === 0) {
        style += ' font-weight: bold;';
      }
      if (r_index === 0) {
        style += 'position: sticky; z-index: 1000; top: 0; background-color: #00bcd4;';
      }
      if (c_index === 0) {
        style += 'position: sticky; left: 0;';
      }
      return style;
    },
    f_showTableFullScreen: function (table, label = '') {
      this.d_tableFullScreenData.label = label;
      this.d_tableFullScreenData.table = table;
      this.d_tableFullScreenData.show = true;
    },
    f_showTableCellFullText: function (c) {
      this.d_selectedTableCellFullText = c.toString();
      this.d_showTableCellFullText = true;
    },
    f_showChartData: function (chart_data) {
      let new_chart_data = {
        backgroundColor: "#fff",
        labels: chart_data.labels ? chart_data.labels : [],
        datasets: []
      };
      for (let d in chart_data.datasets) {
        let new_dataset = {}
        if (chart_data.datasets[d].label !== undefined) {
          new_dataset.label = chart_data.datasets[d].label;
        }
        if (chart_data.datasets[d].lineTension !== undefined) {
          new_dataset.lineTension = chart_data.datasets[d].lineTension;
        }
        if (chart_data.datasets[d].borderColor !== undefined) {
          new_dataset.borderColor = chart_data.datasets[d].borderColor;
        }
        if (chart_data.datasets[d].backgroundColor !== undefined) {
          new_dataset.backgroundColor = chart_data.datasets[d].backgroundColor;
        }
        if (chart_data.datasets[d].showLine !== undefined) {
          new_dataset.showLine = chart_data.datasets[d].showLine;
        }
        if (chart_data.datasets[d].data !== undefined) {
          new_dataset.data = chart_data.datasets[d].data;
        }
        new_chart_data.datasets.push(new_dataset);
      }
      this.chart_data = JSON.parse(JSON.stringify(new_chart_data));
      this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
      this.d_chartSize.chart_type = chart_data.type;
      this.d_chartSize.x_mode = 'standart';
      this.d_chartSize.y_mode = 'standart';
      this.d_chartSize.x_interval_type = 'all';
      this.d_chartSize.height = window.innerHeight - 200;
      this.d_chartSize.width = window.innerWidth - 100;
      this.d_showGraphicView = true;
    },
    f_createChartDataForCorrelation: function (wdm72_result, show_type = '') {
      try {
        let chart_data = {
          backgroundColor: "#fff",
          labels: [],
          datasets: []
        };
        let normalized_list_length = 0;
        for (let p in wdm72_result.parameters) {
          normalized_list_length = wdm72_result.parameters[p]['normalized_list'].length;
          let new_dataset = {
            label: p,
            lineTension: 0,
            borderColor: this.f_calculateRGBA(),
            backgroundColor: 'rgba(255, 255, 255, 0)',
            showLine: true,
            // backgroundColor: hexToRgba('#20a8d8', 90),
            data: wdm72_result.parameters[p]['normalized_list']
          };
          chart_data.datasets.push(new_dataset);
        }
        for (let i = 0; i < normalized_list_length; i++) {
          chart_data.labels.push('');
        }
        if (show_type === 'show_modal') {
          this.chart_data = chart_data;
          this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
          this.d_chartSize.chart_type = 'line';
          this.d_chartSize.x_mode = 'standart';
          this.d_chartSize.y_mode = 'standart';
          this.d_chartSize.x_interval_type = 'all';
          this.d_chartSize.height = window.innerHeight - 200;
          this.d_chartSize.width = window.innerWidth - 100;
          this.d_showGraphicView = true;
        } else {
          return chart_data;
        }
      } catch (err) {}
    },
    f_createChartData: function (val_list, caption, show_type = '') {
      let chart_data = {
        backgroundColor: "#fff",
        labels: [],
        datasets: [{
          label: caption,
          lineTension: 0,
          borderColor: '#20a8d8',
          backgroundColor: '#ffffff00',
          showLine: true,
          // backgroundColor: hexToRgba('#20a8d8', 90),
          data: []
        }]
      };
      for (let i in val_list) {
        chart_data.datasets[0].data.push(val_list[i]);
        chart_data.labels.push('');
        /*
        her 10 adetten bir tanesinde x labelını gösteriyoruz.
        if (parseInt(i) === 0) {
          chart_data.labels.push(x_value);
        } else {
          if (parseInt(i) % 20 === 0) {
            chart_data.labels.push(x_value);
          } else {
            chart_data.labels.push('');
          }
        }
      */
      }
      if (show_type === 'show_modal') {
        this.chart_data = chart_data;
        this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
        this.d_chartSize.chart_type = 'line';
        this.d_chartSize.x_mode = 'standart';
        this.d_chartSize.y_mode = 'standart';
        this.d_chartSize.x_interval_type = 'all';
        this.d_chartSize.height = window.innerHeight - 200;
        this.d_chartSize.width = window.innerWidth - 100;
        this.d_showGraphicView = true;
      } else {
        return chart_data;
      }
    },
    f_showChartViewFromTable: function (table, chart_type, use_last_row = true, use_last_column = false) {
      this.chart_data = {
        labels: [],
        backgroundColor: "#fff",
        datasets: []
      };
      // when cross columns table contains a total row at the end of the table. So we dont get it
      let dataset_length = 0;
      if (table.length > 0) {
        let first_row_length = table[0].length;
        if (use_last_column) {
          dataset_length = first_row_length - 1;
        } else {
          dataset_length = first_row_length - 2;
        }
      }
      for (let d = 0; d < dataset_length; d++) {
        let new_dataset = {};
        if (chart_type === 'line') {
          new_dataset = {
            label: table[0][d + 1],
            lineTension: 0,
            borderColor: this.f_calculateRGBA(),
            backgroundColor: 'rgba(255, 255, 255, 0)',
            showLine: true,
            data: []
          };
        } else if (chart_type === 'bar') {
          let color = this.f_calculateRGBA();
          new_dataset = {
            label: table[0][d + 1],
            backgroundColor: color,
            borderColor: color,
            data: []
          };
        }
        this.chart_data.datasets.push(new_dataset);
      }
      for (let r in table) {
        // First row does not be taken, because this row includes column names
        let row_index = parseInt(r);
        if (row_index > 0) {
          if ((row_index !== table.length - 1) || ((row_index === table.length - 1) && use_last_row)) {
            for (let c in table[row_index]) {
              let col_index = parseInt(c);
              let cell_data = table[row_index][col_index];
              if (col_index === 0) {
                this.chart_data.labels.push(cell_data);
              } else {
                if (col_index !== table[row_index].length - 1) {
                  this.chart_data.datasets[col_index - 1].data.push(cell_data);
                } else {
                  if (use_last_column) {
                    this.chart_data.datasets[col_index - 1].data.push(cell_data);
                  }
                }
              }
            }
          }
        }
      }
      this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
      this.d_chartSize.chart_type = chart_type;
      this.d_chartSize.x_mode = 'standart';
      this.d_chartSize.y_mode = 'standart';
      this.d_chartSize.x_interval_type = 'all';
      this.d_chartSize.height = window.innerHeight - 200;
      this.d_chartSize.width = window.innerWidth - 100;
      this.d_showGraphicView = true;
    },
    f_showChartView: function (type, data) {
      this.chart_data = {
        labels: [],
        datasets: []
      };
      if (type === 'val_frequency_data') {
        let label = data.type === 'wdm' ? data.loc[data.loc.length - 1].label : data.label;
        let k = { 'label': label, 'backgroundColor': 'rgba(255, 0, 0, 0.1)', 'data': [] };
        for (let i in data.val_frequency_data) {
          this.chart_data.labels.push(i);
          k.data.push(data.val_frequency_data[i].total);
        }
        this.chart_data.datasets.push(k);
      } else if (type === 'cross_row_column_data_list') {
        for (let r in data) {
          this.chart_data.labels.push(r);
          for (let c in data[r]) {
            let det = false;
            for (let d in this.chart_data.datasets) {
              if (this.chart_data.datasets[d].label === c) {
                det = true;
                this.chart_data.datasets[d].data.push(data[r][c].total);
                break;
              }
            }
            if (!det) {
              let new_dataset = { 'label': c, 'data': [], 'backgroundColor': this.f_calculateRGBA() };
              new_dataset.data.push(data[r][c].total);
              this.chart_data.datasets.push(new_dataset);
            }
          }
        }
      } else if (type === 'two_p1_and_number_val_list') {
        /*
          data =
          {
           'pr1': {'val_list': [1,2,3,4,5,7] },
           'pr2': {'val_list': [1,2,3,4,5,7] }
          }
        */
        console.log(Object.keys(data));
        let pr_first = Object.keys(data)[0];
        if (pr_first) {
          for (let i in data[pr_first].val_list) {
            this.chart_data.labels.push('');
          }
          for (let pr in data) {
            let new_dataset = {
              label: pr,
              lineTension: 0,
              borderColor: this.f_calculateRGBA(),
              backgroundColor: 'rgba(255, 255, 255, 0)',
              showLine: true,
              data: []
            };
            for (let v in data[pr].val_list) {
              new_dataset.data.push(data[pr].val_list[v]);
            }
            this.chart_data.datasets.push(new_dataset);
          }
        }
      }
      this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
      this.d_chartSize.chart_type = 'line';
      this.d_chartSize.x_mode = 'standart';
      this.d_chartSize.y_mode = 'standart';
      this.d_chartSize.x_interval_type = 'all';
      this.d_chartSize.height = window.innerHeight - 200;
      this.d_chartSize.width = window.innerWidth - 100;
      this.d_showGraphicView = true;
    },
    f_calculateRGBA: function () {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return 'rgba(' + r.toString() + ',' + g.toString() + ',' + b.toString() + ', 1)';
    },
    f_convertSelectedTableToExcel: function (table) {
      let data = { 'column_list': [], 'dataset': table };
      FiletransferService.convert_dataset_to_excel(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            FileSaver.saveAs(resp.data.result.file, "table.xlsx");
          } else {
            alert('errror:', resp.data.message);
          }
        })
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    DateFormatWithHourMin: function (date) {
      return moment(date).format('DD/MM/YYYY hh:mm');
    },
    f_showChartWithTimeOut: function () {
      this.d_showChartView = false;
      setTimeout(function () {
        this.d_showChartView = true;
      }.bind(this), 500)
    }
  },
  watch: {
    'd_wdm72Pagination.current': function () {
      // console.log("d_wdm72Pagination", this.d_wdm72Pagination);
      this.d_wdm72Pagination.start = (this.d_wdm72Pagination.current - 1) * this.d_wdm72Pagination.perpage;
      this.d_wdm72Pagination.end = (this.d_wdm72Pagination.perpage * this.d_wdm72Pagination.current);
      this.p_selectedWdm72Results.splice(0, this.p_selectedWdm72Results.length);
      this.f_getWdm72AnalyzeList();
    },
    'mother_children.send_table_to_article': function () {
      this.f_addTableToArticle(this.mother_children.wdm72_result_data.table, this.mother_children.wdm72_result_data.analyze_no, this.mother_children.wdm72_result_data.table_name, this.mother_children.wdm72_result_data.table_type);
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_deleteWdm72DataConfirmed' && this.StoreModal.data.confirm) {
          this.f_deleteWdm72DataConfirmed(this.StoreModal.data.arguments);
        }
      }
    },
    'd_chartSize.x_interval_type': function () {
      this.d_chartSize.x_interval = 1;
    },
    'd_chartSize.chart_type': function () {
      for (let d in this.chart_data.datasets) {
        if (this.d_chartSize.chart_type === 'line') {
          this.chart_data.datasets[d].lineTension = 0;
          this.chart_data.datasets[d].backgroundColor = 'rgba(255, 255, 255, 0)';
          this.chart_data.datasets[d].showLine = true;
        } else {
          this.chart_data.datasets[d].backgroundColor = this.chart_data.datasets[d].borderColor;
          this.$delete(this.chart_data.datasets[d], 'lineTension');
          this.$delete(this.chart_data.datasets[d], 'showLine');
        }
      }
      this.f_showChartWithTimeOut();
      this.$forceUpdate();
    },
    'd_chartSize.x_interval': function () {
      this.chart_data.labels = [];
      for (let i in this.d_resetChartData.labels) {
        if (parseInt(i) === 0 || parseInt(i) === this.d_resetChartData.labels.length - 1) {
          this.chart_data.labels.push(this.d_resetChartData.labels[i]);
        } else {
          if (parseInt(i) % this.d_chartSize.x_interval === 0) {
            this.chart_data.labels.push(this.d_resetChartData.labels[i]);
          } else {
            this.chart_data.labels.push('');
          }
        }
      }
      this.f_showChartWithTimeOut();
      this.$forceUpdate();
    },
    'd_chartSize.x_mode': function () {
      /*
        chart_data = {
         'datasets': [], these are the matching y values
         'labels': [] ==>>>> this is the x axis
        }
      */
      if (this.d_chartSize.x_mode === 'standart') {
        this.chart_data.labels = JSON.parse(JSON.stringify(this.d_resetChartData.labels));
        for (let d in this.chart_data.datasets) {
          this.chart_data.datasets[d].data = JSON.parse(JSON.stringify(this.d_resetChartData.datasets[d].data));
        }
        this.chart_data.labels = this.d_resetChartData.labels;
      } else {
        let object_list = [];
        for (let i = 0; i < this.chart_data.labels.length; i++) {
          let new_obj = { 'label': this.chart_data.labels[i] };
          for (let d in this.chart_data.datasets) {
            new_obj[d] = this.chart_data.datasets[d].data[i];
          }
          object_list.push(new_obj);
        }
        let param = 'label';
        let asc_or_desc = 1;
        if (this.d_chartSize.x_mode === 'x_descending') {
          asc_or_desc = -1;
        }

        function compare(a, b) {
          // Use toUpperCase() to ignore character casing
          let param_a = '';
          let param_b = '';
          try {
            param_a = a[param].toLocaleLowerCase();
            param_b = b[param].toLocaleLowerCase();
          } catch (err) {
            param_a = a[param];
            param_b = b[param];
          }

          let comparison = 0;
          if (param_a > param_b) {
            comparison = 1;
          } else if (param_a < param_b) {
            comparison = -1;
          }
          return comparison * asc_or_desc;
        }
        object_list.sort(compare);
        this.chart_data.labels = [];
        for (let d in this.chart_data.datasets) {
          this.chart_data.datasets[d].data = [];
        }
        for (let i in object_list) {
          this.chart_data.labels.push(object_list[i]['label']);
          for (let d in this.chart_data.datasets) {
            this.chart_data.datasets[d].data.push(object_list[i][d]);
          }
        }
      }
      this.f_showChartWithTimeOut();
      this.$forceUpdate();
    },
    'd_chartSize.y_mode': function () {
      /*
        chart_data = {
         'datasets': [], these are the matching y values
         'labels': [] ==>>>> this is the x axis
        }
      */
      if (this.d_chartSize.y_mode === 'standart') {
        this.chart_data.labels = JSON.parse(JSON.stringify(this.d_resetChartData.labels));
        for (let d in this.chart_data.datasets) {
          this.chart_data.datasets[d].data = JSON.parse(JSON.stringify(this.d_resetChartData.datasets[d].data));
        }
        this.chart_data.labels = this.d_resetChartData.labels;
      } else {
        let object_list = [];
        for (let i = 0; i < this.chart_data.labels.length; i++) {
          let new_obj = { 'label': this.chart_data.labels[i] };
          for (let d in this.chart_data.datasets) {
            new_obj[d] = this.chart_data.datasets[d].data[i];
          }
          object_list.push(new_obj);
        }
        let param = 0;
        let asc_or_desc = 1;
        if (this.d_chartSize.y_mode === 'y_descending') {
          asc_or_desc = -1;
        }

        function compare(a, b) {
          // Use toUpperCase() to ignore character casing
          const param_a = a[param];
          const param_b = b[param];

          let comparison = 0;
          if (param_a > param_b) {
            comparison = 1;
          } else if (param_a < param_b) {
            comparison = -1;
          }
          return comparison * asc_or_desc;
        }
        object_list.sort(compare);
        this.chart_data.labels = [];
        for (let d in this.chart_data.datasets) {
          this.chart_data.datasets[d].data = [];
        }
        for (let i in object_list) {
          this.chart_data.labels.push(object_list[i]['label']);
          for (let d in this.chart_data.datasets) {
            this.chart_data.datasets[d].data.push(object_list[i][d]);
          }
        }
      }
      this.f_showChartWithTimeOut();
      this.$forceUpdate();
    }
  }
};

</script>

<style>
canvas {
  background-color: white;
}

.cell-class:hover {
  background-color: rgb(233, 253, 233);
}

.confusion-matrix:hover {
  background-color: rgb(233, 253, 233);
}

#column-filter-dropdown-text.b-dropdown-text {
  padding: 0px;
  margin: 0px;
  width: 100%;
  background-color: white;
}

#column-filter-b-dropdown ul.dropdown-menu {
  background-color: #b4bfc1;
}

#descriptive ul.dropdown-menu {
  background-color: white;
}

#descriptive a.dropdown-item {
  color: black !important;
}

#article ul.dropdown-menu {
  background-color: white;
}

#article a.dropdown-item {
  color: black !important;
}

#math_operators ul.dropdown-menu {
  background-color: white;
}

#math_operators a.dropdown-item {
  color: black !important;
}

#comparison ul.dropdown-menu {
  background-color: white;
}

#comparison a.dropdown-item {
  color: black !important;
}

#case_table::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

#case_table::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#case_table::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

