<template>
  <div>
    <template v-if="d_showResults">
      <polestar-result :p_selectedWdm72Results="d_selectedWdm72Results" :d_selectedWdm72Key="d_selectedWdm72Key" :column_list="column_list" :p_selectedProjectData="d_selectedProjectData"></polestar-result>
    </template>
  </div>
</template>

<script>
import GlobalService from '@/services/global';
import PoleStarService from '@/services/polestar';
import WdmService from '@/services/wdm';
import store from '@/store';
import PolestarResult from '@/components/widgets/PolestarResult';
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectStatisticsResults',
  data () {
    return {
      d_selectedProjectData: '',
      d_selectedWdm3Key: '',
      d_selectedWdm72Data: '',
      d_selectedWdm72Key: '',
      d_showResults: false,
      column_list: [],
      d_selectedWdm72Results: [],
      d_wdm72Pagination: {
        'start': 0,
        'end': 10,
        'current': 1,
        'perpage': 10,
        'all_count': 0
      },
      d_filterAnalyzeData: {
        'user_score_list': '',
        'selected_columns': [],
        'filter_analyze_types': [],
        'filter_stats': [],
        'filter_p_value': ['', '0.001', '0.05', '0.06', '0.07', '0.08', '0.09', '0.1'],
        'selected_analyze_types': [],
        'selected_stats': [],
        'show': false,
        'selected_p_value': ''
      },
      d_selectedWdm72Columns: []
    };
  },
  components: {
    PolestarResult
  },
  computed: mapGetters({
    help: 'help'
  }),
  created: function () {},
  mounted () {
    // console.log(this.$route);
    if (this.$route && this.$route.query && this.$route.query.project_id !== undefined && this.$route.query.result_id !== undefined) {
      this.d_selectedWdm3ID = this.$route.query.project_id;
      this.d_selectedWdm3Key = 'wdm3_' + this.$route.query.project_id;
      this.d_selectedWdm72Key = 'wdm72_' + this.$route.query.result_id;
      this.f_goToThisProject();
    }
  },
  methods: {
    f_goToThisProject: function () {
      this.d_selectedProjectData = '';
      this.column_list = [];
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje getiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.get_this_project({ 'wdm3_id': this.d_selectedWdm3ID })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_selectedProjectData = resp.data.result;
            if (this.d_selectedProjectData.design) {
              this.column_list = this.d_selectedProjectData.design.column_list;
            }
            this.f_selectWdm72();
          } else {
            alert('error', resp.data.message);
          }
        });
    },    
    f_selectWdm72: function () {
      this.d_wdm72Pagination = {
        'start': 0,
        'end': 10,
        'current': 1,
        'perpage': 10,
        'all_count': 0
      };
      this.d_filterAnalyzeData = {
        'user_score_list': '',
        'selected_columns': [],
        'filter_analyze_types': [],
        'filter_stats': [],
        'filter_p_value': ['', '0.001', '0.05', '0.06', '0.07', '0.08', '0.09', '0.1'],
        'selected_analyze_types': [],
        'selected_stats': [],
        'show': false,
        'selected_p_value': ''
      };
      GlobalService.get_one_cb_document({ 'key': this.d_selectedWdm72Key, 'bucket': 'polestar' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_selectedWdm72Data = resp.data.result;
            this.d_selectedWdm72Columns = [];
            let x_list = ['selected_row_parameters', 'selected_column_parameters', 'selected_columns'];
            for (let p in x_list) {
              let param = x_list[p];
              try {
                for (let i in this.d_selectedWdm72Data.data.general[param]) {
                  let det = false;
                  for (let c in this.d_selectedWdm72Columns) {
                    if (this.d_selectedWdm72Columns[c].value === i) {
                      det = true;
                      break;
                    }
                  }
                  if (!det) {
                    let col_data = {
                      'label': this.d_selectedWdm72Data.data.general[param][i].name.label,
                      'value': this.d_selectedWdm72Data.data.general[param][i].name.value
                    }
                    this.d_selectedWdm72Columns.push(col_data);
                  }
                }
              } catch (err) {}
            }
            // this.f_getWdm72AnalyzeList();
            this.d_showResults = true;
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_getWdm72AnalyzeList: function () {
      let analyze_data = {
        'pagination': this.d_wdm72Pagination,
        'wdm72_key': this.d_selectedWdm72Key,
        'selected_analyze_types': this.d_filterAnalyzeData.selected_analyze_types,
        'selected_p_value': this.d_filterAnalyzeData.selected_p_value,
        'selected_columns': this.d_filterAnalyzeData.selected_columns,
        'selected_stats': this.d_filterAnalyzeData.selected_stats,
        'user_score_list': this.d_filterAnalyzeData.user_score_list ? this.d_filterAnalyzeData.user_score_list : ''
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analizler getiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.get_analyze_data(analyze_data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedWdm72Results = resp.data.result.analyze_list;
            if (resp.data.result.selected_columns) {
              this.d_filterAnalyzeData.filter_selected_columns = resp.data.result.selected_columns;
            }
            /*
                user_score_list = {
                    'wisdom': {
                        'score_list': {
                            10: {'count': 5, 'selection': 0}
                        }
                    }
                }
            */
            // console.log(resp.data.result.user_score_list);
            if (resp.data.result.user_score_list) {
              if (this.d_filterAnalyzeData.user_score_list) {
                for (let u in resp.data.result.user_score_list) {
                  if (!this.d_filterAnalyzeData.user_score_list[u]) {
                    this.d_filterAnalyzeData.user_score_list[u] = resp.data.result.user_score_list[u];
                  } else {
                    for (let s in resp.data.result.user_score_list[u].score_list) {
                      if (!this.d_filterAnalyzeData.user_score_list[u].score_list[s]) {
                        this.d_filterAnalyzeData.user_score_list[u].score_list[s] = resp.data.result.user_score_list[u].score_list[s];
                      }
                    }
                  }
                }
              } else {
                this.d_filterAnalyzeData.user_score_list = resp.data.result.user_score_list;
              }
            }
            this.d_filterAnalyzeData.filter_stats = resp.data.result.filter_stats;
            this.d_filterAnalyzeData.filter_analyze_types = resp.data.result.filter_analyze_types;
            this.d_wdm72Pagination.all_count = resp.data.result.all_count;
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
        });
    },
  },
  watch: {}
};

</script>

<style type="text/css"></style>

