import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  analyze (data) {
    return Vue.http.post(API_BASE_URL + 'machine_learning/analyze', data);
  },
  save (data) {
    return Vue.http.post(API_BASE_URL + 'machine_learning/save', data);
  },
  train (data) {
    return Vue.http.post(API_BASE_URL + 'machine_learning/train', data);
  },
  machine_learning_list (data) {
    return Vue.http.post(API_BASE_URL + 'v1/machine_learning/list', data);
  },
  machine_learning_update_cells (data) {
    return Vue.http.post(API_BASE_URL + 'v1/machine_learning/update/cells', data);
  }
};
