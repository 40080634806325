<template>
  <div>
    <b-card no-body header-tag="header" :class="[d_fullScreen ?'full-screen-mode' :'normal-mode']">
      <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
        <b-row>
          <b-col :cols="d_editMode ? 8 : 4">
            <template v-if="table_index !== undefined"> <strong>{{ table_index + 1 }} - </strong> </template>
            <template v-if="table_data.label">
              <template v-if="d_editMode">
                <b-form-input class="form-control" v-model="table_data.label"></b-form-input>
              </template>
              <template v-else>
                {{ table_data.label }}
              </template>
            </template>
            <template v-else-if="table_data.name">
              <template v-if="d_editMode">
                <b-form-input class="form-control" v-model="table_data.name"></b-form-input>
              </template>
              <template v-else>
                {{ table_data.name }}
              </template>
            </template>
            <template v-else-if="table_data.table_name">
              <template v-if="d_editMode">
                <b-form-input class="form-control" v-model="table_data.table_name"></b-form-input>
              </template>
              <template v-else>
                {{ table_data.table_name }}
              </template>
            </template>
          </b-col>
          <b-col v-if="!d_editMode" cols="4" v-b-toggle="'collapse_' + table_index" style="cursor: pointer;">
            <img src="@/icon/7149.png" style="width: 2em;" />
          </b-col>
          <b-col cols="4" style="text-align: right">
            <!--             
            <b-dropdown variant="success" size="md" style="margin-right: 10px;">
              <b-dropdown-item @click="f_convertSelectedTableToExcel(table_data.table)"> Excel olarak indir </b-dropdown-item>
            </b-dropdown>
            -->
            <b-dropdown variant="white">
              <template slot="button-content"></template>
              <b-dropdown-item @click="f_carryTable('up')"><i class="fa fa-arrow-circle-o-up fa-lg mt-4"></i>{{ $t('wdm16.13004') }}</b-dropdown-item>
              <b-dropdown-item @click="f_carryTable('down')"><i class="fa fa-arrow-circle-down fa-lg mt-4"></i>{{ $t('wdm16.905') }}</b-dropdown-item>
              <b-dropdown-item @click="f_carryTable('top')"><i class="fa fa-arrow-up fa-lg mt-4"></i>{{ $t('wdm16.13005') }}</b-dropdown-item>
              <b-dropdown-item @click="f_carryTable('bottom')"><i class="fa fa-arrow-down fa-lg mt-4"></i>{{ $t('wdm16.13006') }}</b-dropdown-item>
            </b-dropdown>
            <b-button size="md" :variant="d_editMode ?'warning' :'secondary'" :style="d_editMode ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_editMode ? d_editMode = false : d_editMode = true">
              <img src="@/icon/42729.png" title="Tablo Düzenleme Modu" style="width: 2em;" />
            </b-button>
            <b-button v-if="wdmr_data !== undefined" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11442')" @click="f_deleteTableFromWdmr()">
              <img src="@/icon/2960590.png" style="width: 2em;" />
            </b-button>
            <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11443')" @click="f_addTableToArticle()">
              <img src="@/icon/70094.png" style="width: 2em;" />
            </b-button>
            <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.11444')" @click="f_convertSelectedTableToExcel(table_data.table)">
              <img src="@/icon/2237824.png" style="width: 2em;" />
            </b-button>
            <b-button size="md" :variant="d_fullScreen ?'warning' :'secondary'" :style="d_fullScreen ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
              <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse :id="'collapse_' + table_index" :visible="true">
        <b-row>
          <b-col cols="6">
            <b-form-input class="form-control" :placeholder="$t('wdm16.11445')" v-model="d_searchTextRow"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-input class="form-control" :placeholder="$t('wdm16.11446')" v-model="d_searchTextColumn"></b-form-input>
          </b-col>
        </b-row>
        <b-row v-if="d_editMode" style="margin: 5px;">
          <b-col cols="12">
            <b-dropdown variant="success" style="margin-right: 5px;">
              <template slot="button-content">
                işlemler
              </template>
              <b-dropdown-item @click="f_cellValView('row_percentages')">Satır sonundaki değeri toplam kabul ederek yüzdeleme yap</b-dropdown-item>
              <b-dropdown-item @click="f_cellValView('column_percentages')">Kolon sonundaki değeri toplam kabul ederek yüzdeleme yap</b-dropdown-item>
              <b-dropdown-item @click="f_footnote()">Dipnot Ekle</b-dropdown-item>
            </b-dropdown>
            <b-button variant="primary" style="margin-right: 5px;" @click="f_applyEditChanges()">
              uygula
            </b-button>
            <b-button variant="primary" style="margin-right: 5px;" @click="f_resetChanges()">
              reset
            </b-button>
          </b-col>
        </b-row>
        <b-row>
        <b-col cols="6">
          <b-pagination size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="d_tablePagination.current" :total-rows="table_data.table.length" :per-page="d_tablePagination.perpage"></b-pagination>
        </b-col>
        <b-col cols="6"></b-col>
      </b-row>
        <div :style="f_calculateTableStyle()">
          <table v-if="!d_editMode && d_tableShow" style="margin: 10px;">
            <tbody>
              <template v-for="(r, r_index) in table_data.table">
                <tr v-if="f_searchCrossRows(r, r_index, table_data.table.length)" :style="r_index % 2 === 0 ?'border: solid 1px black; background-color: #f6fcf5; line-height: 30px;' :'border: solid 1px black; line-height: 30px'">
                  <template v-for="(c, c_index) in r">
                    <td v-if="f_searchCrossColumns(c_index, table_data.table[0], r.length)" :style="f_calculateTableTdStyle(r_index, c_index)" :title="c">
                      <template v-if="d_editMode">
                        <b-form-input class="form-control" v-model="table_data.table[r_index][c_index]"></b-form-input>
                      </template>
                      <template v-else>
                        <template v-if="c.toString().length > 100">
                          {{ c.toString().substring(0, 100) }} <i style="color: red; cursor: pointer;" @click="f_showTableCellFullText(c)" :title="c.toString()">...</i>
                        </template>
                        <template v-else>
                          {{ c }}
                        </template>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
          <table v-if="d_editMode" style="margin: 10px;">
            <tbody>
              <template v-if="d_editTable.table.length > 0">
                <tr>
                  <td></td>
                  <td v-for="(c, c_index) in d_editTable.table[0]">
                    <b-dropdown variant="success" style="margin-right: 5px;">
                      <template slot="button-content"> </template>
                      <b-dropdown-item @click="f_addNewColumn(c_index, 'right')">Sağına Sütun Ekle</b-dropdown-item>
                      <b-dropdown-item @click="f_addNewColumn(c_index, 'left')">Soluna Sütun Ekle</b-dropdown-item>
                      <b-dropdown-item @click="f_deleteColumn(c_index)">Sil </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <template v-for="(r, r_index) in d_editTable.table">
                <tr v-if="f_searchCrossRows(r, r_index, d_editTable.table.length)">
                  <td>
                    <b-dropdown variant="success" style="margin-right: 5px;">
                      <template slot="button-content"> </template>
                      <b-dropdown-item @click="f_addNewRow(r_index, 'up')">Üstüne Satır Ekle</b-dropdown-item>
                      <b-dropdown-item @click="f_addNewRow(r_index, 'down')">Altına Satır Ekle</b-dropdown-item>
                      <b-dropdown-item @click="f_deleteRow(r_index)">Satırı Sil</b-dropdown-item>
                    </b-dropdown>
                  </td>
                  <template v-for="(c, c_index) in r">
                    <td v-if="f_searchCrossColumns(c_index, d_editTable.table[0], r.length)" :style="f_calculateTableTdStyle(r_index, c_index)" :title="c">
                      <b-form-input class="form-control" v-model="d_editTable.table[r_index][c_index]"></b-form-input>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
          <template v-if="!d_editMode">
            <template v-if="table_data.footnotes" v-for="(footnote, footnote_index) in table_data.footnotes">
              <b-row style="margin: 0px;">
                <b-col cols="1">
                  {{ footnote_index + 1 }} )
                </b-col>
                <b-col cols="11">
                  {{ footnote.text }}
                </b-col>
              </b-row>
            </template>
          </template>
          <template v-if="d_editMode">
            <template v-if="d_editTable.footnotes" v-for="(footnote, footnote_index) in d_editTable.footnotes">
              <b-row style="margin: 0px;">
                <b-col cols="1">
                  {{ footnote_index + 1 }} )
                  <b-button variant="danger" style="margin-right: 5px;" @click="f_deleteFootNote(footnote_index)"> sil </b-button>
                </b-col>
                <b-col cols="11">
                  <b-textarea class="form-control" v-model="footnote.text"></b-textarea>
                </b-col>
              </b-row>
            </template>
          </template>
        </div>
      </b-collapse>
    </b-card>
    <b-modal v-if="d_showTableCellFullText" id="d_showTableCellFullText" v-model="d_showTableCellFullText" scrollable hide-footer :title="$t('wdm16.11447')" centered header-bg-variant="info" header-text-variant="dark">
      <p class="my-4" style="word-wrap: break-word;">
        {{ d_selectedTableCellFullText }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import WdmService from '@/services/wdm';
import FiletransferService from '@/services/file_transfer';
import FileSaver from 'file-saver';
import { mapGetters } from 'vuex';
export default {
  name: 'WisdomTable',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang'
  }),
  components: {},
  created: function () {
    // console.log('wdm72_result_data : ', this.wdm72_result_data);
  },
  props: {
    table_data: {
      type: Object,
      required: true
    },
    table_index: {
      type: Number,
      required: false
    },
    wdm72_result_data: {
      type: Object,
      required: false
    },
    mother_children: {
      type: Object,
      required: false
    },
    wdmr_data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      d_tableShow: true,
      d_editTable: '',
      d_editMode: false,
      d_tablePagination: {
        'start': 0,
        'end': 100,
        'current': 1,
        'perpage': 100,
        'all_count': 0
      },
      d_searchRowTextTimer: { 'timeout': '', 'text': '' },
      d_searchColumnTextTimer: { 'timeout': '', 'text': '' },
      d_fullScreen: false,
      d_searchTextRow: '',
      d_searchTextColumn: '',
      d_showTableCellFullText: false,
      d_selectedTableCellFullText: ''
    };
  },
  methods: {
    f_deleteColumn: function (column_index) {
      for (let row_ind in this.d_editTable.table) {
        this.d_editTable.table[row_ind].splice(column_index, 1);
      }
      this.$forceUpdate();
    },
    f_deleteRow: function (row_ind) {
      this.d_editTable.table.splice(row_ind, 1);
      this.$forceUpdate();
    },
    f_addNewColumn: function (column_index, direction) {
      if (direction === 'right') {
        for (let row_ind in this.d_editTable.table) {
          if (column_index === this.d_editTable.table[row_ind].length - 1) {
            this.d_editTable.table[row_ind].push('');
          } else {
            this.d_editTable.table[row_ind].splice(column_index + 1, 0, '');
          }
        }
      } else if (direction === 'left') {
        for (let row_ind in this.d_editTable.table) {
          if (column_index === 0) {
            this.d_editTable.table[row_ind].splice(0, 0, '');
          } else {
            this.d_editTable.table[row_ind].splice(column_index - 1, 0, '');
          }
        }
      }
      this.$forceUpdate();
    },
    f_addNewRow: function (row_ind, direction) {
      let new_row = [];
      for (let i in this.d_editTable.table[0]) {
        new_row.push('');
      }
      if (direction === 'down') {
        if (row_ind === this.d_editTable.table.length - 1) {
          this.d_editTable.table.push(new_row);
        } else {
          this.d_editTable.table.splice(row_ind + 1, 0, new_row);
        }
      } else {
        if (row_ind === 0) {
          this.d_editTable.table.splice(0, 0, new_row);
        } else {
          this.d_editTable.table.splice(row_ind, 0, new_row);
        }
      }
      this.$forceUpdate();
    },
    f_deleteFootNote: function (footnote_index) {
      this.d_editTable.footnotes.splice(footnote_index, 1);
      this.$forceUpdate();
    },
    f_tableShow: function () {
      this.d_tableShow = false;
      setTimeout(function () {
        this.d_tableShow = true;
        this.$forceUpdate();
      }.bind(this), 1000);
    },
    f_footnote: function () {
      if (!this.d_editTable.footnotes) {
        this.d_editTable.footnotes = [];
      }
      this.d_editTable.footnotes.push({ 'text': '' });
      this.$forceUpdate();
    },
    f_cellValView: function (cell_view_type) {
      if (cell_view_type === 'row_percentages') {
        for (let row_index in this.d_editTable.table) {
          if (parseInt(row_index) > 0) {
            for (let col_index in this.d_editTable.table[row_index]) {
              // If the col_index is not the first or the last column, we calculate the percentage of this val in last column val.
              if (parseInt(col_index) > 0 && parseInt(col_index) < this.d_editTable.table[row_index].length - 1) {
                try {
                  if (this.d_editTable.table[row_index][col_index] !== '') {
                    let col_val = parseFloat(this.d_editTable.table[row_index][col_index]);
                    let last_col_val = parseFloat(this.d_editTable.table[row_index][this.d_editTable.table[row_index].length - 1]);
                    let x = col_val.toString() + ' (%' + (100 * col_val / last_col_val).toFixed(1).toString() + ')';
                    this.d_editTable.table[row_index][col_index] = x;
                  }
                } catch (err) {}
              }
              if (parseInt(col_index) === this.d_editTable.table[row_index].length - 1) {
                try {
                  if (this.d_editTable.table[row_index][this.d_editTable.table[row_index].length - 1] !== '') {
                    let last_col_val = parseFloat(this.d_editTable.table[row_index][this.d_editTable.table[row_index].length - 1]);
                    let x = last_col_val.toString() + ' (%100)';
                    this.d_editTable.table[row_index][col_index] = x;
                  }
                } catch (err) {}
              }
            }
          }
        }
      } else if (cell_view_type === 'column_percentages') {
        for (let row_index in this.d_editTable.table) {
          if (parseInt(row_index) > 0) {
            for (let col_index in this.d_editTable.table[row_index]) {
              // If the col_index is not the first or the last column, we calculate the percentage of this val in last column val.
              if (parseInt(col_index) > 0 && parseInt(row_index) !== this.d_editTable.table.length - 1) {
                try {
                  if (this.d_editTable.table[row_index][col_index] !== '') {
                    let col_val = parseFloat(this.d_editTable.table[row_index][col_index]);
                    let last_row_val = parseFloat(this.d_editTable.table[this.d_editTable.table.length - 1][col_index]);
                    let x = col_val.toString() + ' (%' + (100 * col_val / last_row_val).toFixed(1).toString() + ')';
                    this.d_editTable.table[row_index][col_index] = x;
                  }
                } catch (err) {}
              }
              if (parseInt(col_index) > 0 && parseInt(row_index) === this.d_editTable.table.length - 1) {
                try {
                  if (this.d_editTable.table[this.d_editTable.table.length - 1][col_index] !== '') {
                    let last_row_val = parseFloat(this.d_editTable.table[this.d_editTable.table.length - 1][col_index]);
                    if (last_row_val !== NaN) {
                      let x = last_row_val.toString() + ' (%100)';
                      this.d_editTable.table[row_index][col_index] = x;
                    }
                  }
                } catch (err) {}
              }
            }
          }
        }
      }
      this.$forceUpdate();
    },
    f_applyEditChanges: function () {
      this.table_data.table = JSON.parse(JSON.stringify(this.d_editTable.table));
      if (this.d_editTable.footnotes) {
        this.table_data.footnotes = JSON.parse(JSON.stringify(this.d_editTable.footnotes));
      }
      this.f_motherChildren('forceUpdate');
      let modal_data = {
        'type': 'alert',
        'text': 'Tablo verileri güncellendi.',
        'centered': true,
        'title': 'Tablo verileri güncelleme hakkında'
      };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_resetChanges: function () {
      this.d_editTable.table = JSON.parse(JSON.stringify(this.table_data.table));
      if (this.table_data.footnotes) {
        this.d_editTable.footnotes = JSON.parse(JSON.stringify(this.table_data.footnotes));
      }
      this.f_motherChildren('forceUpdate');
      let modal_data = {
        'type': 'alert',
        'text': 'İşlemler geri alındı.',
        'centered': true,
        'title': 'Tablo verileri güncelleme hakkında'
      };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    list_param_order: function (list, index, direction) {
      let index_data = list[index];
      let count = list.length;
      let friend = '';
      if (direction === 'up') {
        if (index !== 0) {
          friend = list[index - 1];
          list[index - 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'down') {
        if (index !== count - 1) {
          friend = list[index + 1];
          list[index + 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'top') {
        list.splice(index, 1);
        list.splice(0, 0, index_data);
      } else if (direction === 'bottom') {
        list.splice(index, 1);
        list.push(index_data);
      }
      this.f_motherChildren('forceUpdate');
    },
    f_carryTable: function (direction) {
      // console.log("this.table_index", this.table_index);
      this.list_param_order(this.wdmr_data.tables, this.table_index, direction);
    },
    f_showTableCellFullText: function (c) {
      this.d_selectedTableCellFullText = c.toString();
      this.d_showTableCellFullText = true;
    },
    f_addTableToArticle: function () {
      // console.log('table_data : ', this.table_data);
      let table_name = '';
      let table_type = '';
      if (this.table_data.type) {
        table_type = this.table_data.type;
      }
      if (this.table_data.label) {
        table_name = this.table_data.label;
      } else if (this.table_data.table_name) {
        table_name = this.table_data.table_name;
      } else if (this.table_data.name) {
        table_name = this.table_data.name;
      }
      this.mother_children.wdm72_result_data = {
        'analyze_no': this.wdm72_result_data.analyze_no,
        'wdm72_result_ind': this.wdm72_result_data.wdm72_result_ind,
        'table': this.table_data.table,
        'table_name': table_name,
        'table_type': table_type,
      };
      // console.log('this.mother_children.wdm72_result_data : ', this.mother_children.wdm72_result_data);
      this.f_motherChildren('send_table_to_article');
    },
    f_motherChildren: function (opt) {
      if (this.mother_children[opt]) {
        this.mother_children[opt] = 0;
      } else {
        this.mother_children[opt] = 1;
      }
    },
    f_searchCrossRows: function (row, row_index, rows_length) {
      // if (this.d_searchTextRow && row_index !== 0 && row_index !== rows_length - 1) {
      if (this.d_searchTextRow && row_index !== 0) {
        let text_list = this.d_searchTextRow.split(',');
        for (let i in text_list) {
          text_list[i] = text_list[i].replace(/ /g, "");
        }
        for (let t in text_list) {
          if (text_list[t]) {
            if (row[0].toLocaleLowerCase().indexOf(text_list[t].toLocaleLowerCase()) !== -1) {
              return true;
            }
          }
        }
      } else {
        if (row_index >= this.d_tablePagination.start && row_index <= this.d_tablePagination.end) {
          return true;
        }
      }
      return false;
    },
    f_searchCrossColumns: function (col_index, first_row, col_length) {
      // if (this.d_searchTextColumn && col_index !== 0 && col_index !== col_length - 1) {
      if (this.d_searchTextColumn && col_index !== 0) {
        let text_list = this.d_searchTextColumn.split(',');
        for (let i in text_list) {
          text_list[i] = text_list[i].replace(/ /g, "");
        }
        for (let t in text_list) {
          if (text_list[t]) {
            if (first_row[col_index].toLocaleLowerCase().indexOf(text_list[t].toLocaleLowerCase()) !== -1) {
              return true;
            }
          }
        }
        return false;
      } else {
        return true;
      }
    },
    f_calculateTableStyle: function () {
      let style = '';
      if (this.d_fullScreen) {
        style = 'overflow-x: auto; margin-bottom: 10px; height: 100%;';
      } else {
        style = 'overflow-x: auto; height: 100%; margin-bottom: 10px;';
      }
      return style;
    },
    f_calculateTableStyleOld: function () {
      if (this.d_fullScreen) {
        let style = 'overflow-x: auto; margin-bottom: 200px; height: ' + (window.innerHeight - 50).toString() + 'px;';
        return style;
      } else {
        return 'overflow-x: auto; height: 100%; margin-bottom: 200px;';
      }
    },
    f_deleteTableFromWdmr: function () {
      let modal_data = { 'type': 'confirm', 'text': 'Tabloyu silmek istediğinize emin misiniz?', 'centered': true, 'title': 'Tablo Silme İşlemi Hakkında', 'function_name': 'f_deleteWdmTableConfirmed', 'confirm': false, 'arguments': [] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_deleteWdmTableConfirmed: function () {
      let buck = 'wisdom';
      if (this.wdmr_data.type === 'wdm73') {
        buck = 'polestar';
      }
      let data = {
        'wdmr_key': this.wdmr_data.type + '_' + this.wdmr_data.id,
        'buck': buck, // wisdom, polestar, ...
        'table_ind': this.table_index
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tablo silme işlemi uygulanıyor. Lütfen bekleyiniz.' } });
      WdmService.delete_table_wdmr(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.wdmr_data.tables.splice(this.table_index, 1);
            this.$forceUpdate();
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    },
    f_calculateTableTdStyle: function (r_index, c_index) {
      let style = 'min-width: 100px; text-align: center; border: solid 1px #c3d8d8;';
      if (r_index === 0 || c_index === 0) {
        style += ' font-weight: bold; font-size: 10px;';
      }
      if (r_index === 0) {
        style += 'position: sticky; z-index: 1000; top: 0; background-color: #00bcd4;';
      }
      if (c_index === 0) {
        style += 'position: sticky; left: 0;';
      }
      return style;
    },
    f_convertSelectedTableToExcel: function (table) {
      let data = { 'column_list': [], 'dataset': table };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Excele aktarma işlemi yapılıyor. Lütfen bekleyiniz.' } });
      FiletransferService.convert_dataset_to_excel(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            FileSaver.saveAs(resp.data.result.file, "table.xlsx");
          } else {
            alert('errror:', resp.data.message);
          }
        })
    }
  },
  watch: {
    'd_tablePagination.current': function () {
      this.d_tablePagination.start = (this.d_tablePagination.current - 1) * this.d_tablePagination.perpage;
      this.d_tablePagination.end = (this.d_tablePagination.perpage * this.d_tablePagination.current) - 1;
      this.f_tableShow();
    },
    'd_searchColumnTextTimer.text': function () {
      clearTimeout(this.d_searchColumnTextTimer.timeout);
      let vm = this;
      this.d_searchColumnTextTimer.timeout = setTimeout(function () {
        vm.d_searchTextColumn = vm.d_searchColumnTextTimer.text;
      }, 1000);
    },
    'd_searchRowTextTimer.text': function () {
      clearTimeout(this.d_searchRowTextTimer.timeout);
      let vm = this;
      this.d_searchRowTextTimer.timeout = setTimeout(function () {
        vm.d_searchTextRow = vm.d_searchRowTextTimer.text;
      }, 1000);
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_deleteWdmTableConfirmed' && this.StoreModal.data.confirm) {
          this.f_deleteWdmTableConfirmed(this.StoreModal.data.arguments);

        }
      }
    },
    'd_editMode': function () {
      if (this.d_editMode) {
        this.d_editTable = JSON.parse(JSON.stringify(this.table_data));
      } else {
        this.d_editTable = '';
      }
    }
  }
};

</script>

<style type="text/css">
.normal-mode {
  margin-bottom: 3px;
}

.full-screen-mode {
  margin-bottom: 3px;
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

