<template>
  <span>
    <strong v-if="col_ind !== undefined"> ({{ col_ind + 1 }}) </strong>
    <span>
      - {{ wdm_name.label }} -
      <br v-if="show_wdm_name_upper">
    </span>
  <template v-for="(x, x_ind) in loc">
    <span> {{ x.label }}. </span>
    <template v-if="x.value === 'anatomy'">
      <span v-if="col.anatomy_use === 'this'">
        <span v-if="col.this_anatomy && col.this_anatomy.label"> {{ col.this_anatomy.label }}. </span>
      <span v-else style="color: red;"> anatomi bölgesi seçiniz </span>
      </span>
      <span v-else-if="col.anatomy_use === 'parent'"> <span v-if="col.this_parent && col.this_parent.label"> {{ col.this_parent.label }}. </span>
      <span v-else style="color: red;"> anatomi ailesi seçiniz </span> </span>
      <span v-else-if="col.anatomy_use === 'any'"> <span> {{ $t('wdm16.13075') }} </span> </span>
    </template>
  </template>
  <span v-if="col.parameter_val_list && col.parameter_val_list.length > 0">. {{ f_showValList() }} </span>
  <span v-if="col.starting_at"> ( {{ DateFormat(col.starting_at) }} - </span>
  <span v-if="col.ending_at"> {{ DateFormat(col.ending_at) }} ) </span>
  </span>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'WdmParameterLocation',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {},
  created: function () {},
  props: {
    col: {
      type: Object,
      required: true
    },
    loc: {
      type: Array,
      required: true
    },
    wdm_name: {
      type: Object,
      required: true
    },
    show_wdm_name_upper: {
      type: Boolean,
      required: false
    },
    col_ind: {
      type: Number,
      required: false
    }
  },
  data () {
    return {};
  },
  methods: {
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    f_showValList: function () {
      let x = '';
      for (let i in this.col.parameter_val_list) {
        if (this.col.parameter_val_list[i].label) {
          x += this.col.parameter_val_list[i].label;
        } else {
          x += this.col.parameter_val_list[i];
        }
        if (parseInt(i) !== this.col.parameter_val_list.length - 1) {
          x += ',';
        }
      }
      return x;
    }
  }
};

</script>

