import { DateFunctions as DateFunctions } from './date_functions';
import store from '../../store';

class ClsWdmrFreeValControl {
  static wdmr_free_val_control = function(wdmr) {}

};

class ClsWdmrForever {
  // schema tek başına gönderilmeli
  // tek bir komple wdm döngüsü içerisinde çok şeyi yapmak amaçlı operation değeri gönderiyoruz.
  // operation list = name, require, delete // hangi operasyonu yapmak istiyor isek onun listesini çağırıyoruz.
  // We are looping in both wdm and its wdmr data in turn function
  // Requirement parameter control works only in filled data, We will give a new argument to analyze not filled data parameters to be required in the future.
  static turn = function(wdmr, wdm, operation = []) {
    this.main_wdmr_data = wdmr;
    this.only_one_param_is_filled = false;
    this.R = operation.indexOf('require') !== -1 ? true : false; // require kontrolü yap
    this.N = operation.indexOf('name') !== -1 ? true : false; // isimlendirme yap
    this.D = operation.indexOf('delete') !== -1 ? true : false; // boş parametreleri temizle
    this.S = operation.indexOf('schema') !== -1 ? true : false; // şema olamayacakları kaldır
    this.LangIdDeletion = operation.indexOf('lang_id_deletion') !== -1 ? true : false; // delete lang id in parameters
    this.ValLabelDeletion = operation.indexOf('val_label_deletion') !== -1 ? true : false; // delete val labels
    this.calculate_eval = operation.indexOf('calculate_eval') !== -1 ? true : false; // Calculate parameters if has eval function
    // içi dolu olmayan parametreler temizleniyor
    // wdmr adı oluşturuluyor
    // require alanlar kontrol ediliyor. Herhangi bir parametre calculate_if ile uygunsa görünür hale geliyor. Dolayısıyla açık olup ta required olan parametre mutlaka doldurulması gerekiyor.
    this.wdmr_require_alerts = [];
    let name = "";
    let schema = false;
    let wdmr_bck = JSON.parse(JSON.stringify(wdmr));
    if (this.S) {
      schema = JSON.parse(JSON.stringify(wdmr));
    }
    for (let i in wdm.param_group.param_order) {
      let watch_data = { 'index_list': [] };
      watch_data.index_list.push(wdm.param_group.param_order[i]);
      let pg = wdm.param_group.param_order[i];
      if (pg === "anatomy" && wdmr_bck['data'] && wdmr_bck['data'].anatomy) {
        for (let ana in wdmr_bck['data'].anatomy) {
          if (wdmr_bck['data'].anatomy[ana] && Object.keys(wdmr_bck['data'].anatomy[ana]).length > 0) {
            let x = false;
            let schema_data_location = schema ? schema['data'].anatomy[ana].signs : schema;
            x = this.main_param_func(wdmr_bck['data'].anatomy[ana].signs, wdm.anatomy, schema_data_location, watch_data);
            if (x) {
              if (this.N) {
                name += wdmr_bck['data'].anatomy[ana].name.label + " [" + x + "]; ";
              }
            }
          } else {
            if (this.D) {
              delete wdmr_bck['data'].anatomy[ana];
            }
            if (this.S) {
              delete schema['data'].anatomy[ana];
            }
          }
        }
      } else {
        if (wdmr_bck['data'][pg] && Object.keys(wdmr_bck['data'][pg]).length > 0) {
          let schema_data_location = schema ? schema['data'][pg] : schema;
          name += this.main_param_func(wdmr_bck['data'][pg], wdm[pg], schema_data_location, watch_data);
        } else {
          if (this.D) {
            delete wdmr_bck['data'][pg];
          }
          if (this.S) {
            delete schema['data'][pg];
          }
        }
      }
    }
    // console.log('name: ', name);
    // console.log('wdmr_require_alerts: ', this.wdmr_require_alerts);
    let schema_data = schema ? JSON.parse(JSON.stringify(schema.data)) : {};
    // if (!this.only_one_param_is_filled && this.wdmr_require_alerts.length === 0) {
    //   wdmr = JSON.parse(JSON.stringify(wdmr_bck));
    // }
    wdmr = JSON.parse(JSON.stringify(wdmr_bck));
    let return_data = {
      'name': name,
      'wdmr_require_alerts': this.wdmr_require_alerts,
      'only_one_param_is_filled': this.only_one_param_is_filled,
      'schema': schema_data,
      'wdmr': wdmr
    }
    return return_data;
  };

  static main_param_func = function(data, upper_parameters, schema, watch_data) {
    let name = "";
    // console.log('watch_data: ', watch_data);
    for (let i in upper_parameters.param_order) {
      if (parseInt(i) === 0) {
        watch_data.index_list.push(upper_parameters.param_order[i]);
      } else {
        watch_data.index_list[watch_data.index_list.length - 1] = upper_parameters.param_order[i];
      }
      // console.log(watch_data.index_list);
      let param = upper_parameters.param_order[i];
      let param_data = upper_parameters.parameters[param];
      if (this.S && param_data.not_schema) {
        delete schema[param];
      }
      this.f_controlParamFilled(param_data, data, param, schema)
      if (!data[param]) {
        continue;
      }
      if (this.S && !schema[param]) {
        continue;
      }
      if (param_data.type === "object_list_options") {
        if (typeof data[param] === "object" && Object.keys(data[param]).length > 0) {
          let schema_data_location = schema ? schema[param] : schema;
          name += this.object_list_options_parameter(data[param], param_data, param_data.name.label, schema_data_location, watch_data);
        } else {
          if (param_data.required && param_data.required === 'yes') {
            if (this.wdmr_require_alerts.indexOf(param_data.name) === -1) {
              this.wdmr_require_alerts.push(param_data.name);
            }
          }
        }
      } else if (param_data.type === "list_parameter" && data[param] && data[param].list && data[param].list.length > 0) {
        let schema_data_location = schema ? schema[param].list : schema;
        name += this.list_parameter(data[param].list, param_data, schema_data_location, watch_data, param_data.name.label);
      } else if (param_data.type !== "object_list" && data[param] && param_data.type !== "object_list_options") {
        name += this.other_parameter(data, upper_parameters, schema, watch_data, param);
      }
    }
    /*
    if (data['name']) {
      name += this.other_parameter(data, upper_parameters, schema);
    } else {}
    */
    return name;
  };

  static f_controlParamFilled = function(param_data, data, param, schema) {
    if (param_data.type === 'object_list_options') {
      if (data[param] !== undefined && Object.keys(data[param]).length === 0) {
        if (this.D) {
          delete data[param];
        }
        if (this.S) {
          delete schema[param];
        }
      }
    } else if (['object_list'].indexOf(param_data.type) !== -1) {
      if (data[param] !== undefined) {
        if (this.D) {
          delete data[param];
        }
        if (this.S) {
          delete schema[param];
        }
      }
    } else if (['select', 'radiogroup'].indexOf(param_data.type) !== -1) {
      if (data[param] !== undefined && (data[param].val === undefined || (data[param].val !== undefined && (typeof data[param].val !== 'object' || (typeof data[param].val === 'object' && (data[param].val.value === undefined || data[param].val.label === undefined)))))) {
        if (this.D) {
          delete data[param];
        }
        if (this.S) {
          delete schema[param];
        }
      }
    } else if (['checkbox'].indexOf(param_data.type) !== -1) {
      if (data[param] !== undefined && (data[param].val === undefined || (data[param].val !== undefined && (typeof data[param].val !== 'list' || (typeof data[param].val === 'list' && data[param].val.length === 0))))) {
        if (this.D) {
          delete data[param];
        }
        if (this.S) {
          delete schema[param];
        }
      }
    } else if (['image', 'text', 'textarea', 'plain_text', 'float', 'integer', 'date', 'datetime', 'time', 'pdf', 'html_editor'].indexOf(param_data.type) !== -1) {
      if (data[param] !== undefined && (data[param].val === undefined || (data[param].val !== undefined && (typeof data[param].val !== 'string' || (typeof data[param].val === 'string' && !data[param].val))))) {
        if (this.D) {
          delete data[param];
        }
        if (this.S) {
          delete schema[param];
        }
      }
    }
  };

  static other_parameter = function(data, upper_parameters, schema, watch_data, param = null) {
    let name = "";
    // ???????????????????????? When this param argument comes as null
    if (param !== null) {
      let param_data = upper_parameters.parameters[param];
      if (this.S && param_data.not_schema) {
        delete schema[param];
      }
      if ((data[param] && data[param]['val']) || (this.calculate_eval && param_data.eval && param_data.eval.parameters.length > 0)) {
        if (this.LangIdDeletion) {
          if (data[param].val.wdm16_id) {
            delete data[param].val.wdm16_id;
          }
        }
        this.only_one_param_is_filled = true;
        if (["date", "datetime"].indexOf(param_data.type) !== -1) {
          if (this.N && param_data.naming) {
            if (data[param]['val']) {
              if (data[param]['val'].includes('T')) {
                if (!param_data.dont_use_label) {
                  name += param_data.name.label + ': ';
                }
                name += DateFunctions.DateFormat(data[param]['val'].split('T')[0]) + ' ' + data[param]['val'].split('T')[1].split(':')[0] + ':' + data[param]['val'].split('T')[1].split(':')[1] + ', ';
              } else if (data[param]['val'].includes(' ')) {
                if (!param_data.dont_use_label) {
                  name += param_data.name.label + ': ';
                }
                name += DateFunctions.DateFormat(data[param]['val'].split(' ')[0]) + ' ' + data[param]['val'].split(' ')[1].split(':')[0] + ':' + data[param]['val'].split(' ')[1].split(':')[1] + ', ';
              } else {
                if (!param_data.dont_use_label) {
                  name += param_data.name.label + ': ';
                }
                name += DateFunctions.DateFormat(data[param]['val']) + ', ';
              }
            }
          }
        } else if (["select", "radiogroup"].indexOf(param_data.type) !== -1 && data[param]['val']['label']) {
          if (this.N && param_data.naming) {
            if (!param_data.dont_use_label) {
              name += param_data.name.label + ': ';
            }
            name += data[param]['val']['label'];
            if ((param_data.lab_unit || param_data.show_dosage_units || param_data.show_size_units || param_data.show_weight_units) && data[param]['unit'] && data[param]['unit']['label']) {
              name += ' ' + data[param]['unit']['label'];
            }
            name += ", ";
          }
          if (this.D) {
            if (data[param].val.translation) {
              delete data[param].val.translation;
            }
          }
          if (this.LangIdDeletion) {
            if (data[param].val.wdm16_id) {
              delete data[param].val.wdm16_id;
            }
          }
          if (this.ValLabelDeletion) {
            if (data[param].val.label) {
              delete data[param].val.label;
            }
          }
        } else if (["checkbox"].indexOf(param_data.type) !== -1 && data[param]['val'].length > 0) {
          if (!param_data.dont_use_label) {
            name += param_data.name.label + ': ';
          }
          for (let op_ind in data[param]['val']) {
            if (this.N && param_data.naming) {
              name += data[param]['val'][op_ind].label;
              if (parseInt(op_ind) !== data[param]['val'].length - 1) {
                name += ', ';
              }
            }
            if (this.D) {
              if (data[param].val[op_ind].translation) {
                delete data[param].val[op_ind].translation;
              }
            }
            if (this.LangIdDeletion) {
              if (data[param].val[op_ind].wdm16_id) {
                delete data[param].val[op_ind].wdm16_id;
              }
            }
            if (this.ValLabelDeletion) {
              if (data[param].val[op_ind].label) {
                delete data[param].val[op_ind].label;
              }
            }
          }
        } else if (["integer", "float", "textarea", "text", "plain_text"].indexOf(param_data.type) !== -1) {
          if (this.N && param_data.naming) {
            if (!param_data.dont_use_label) {
              name += param_data.name.label + ': ';
            }
            name += data[param]['val'];
            if ((param_data.lab_unit || param_data.show_dosage_units || param_data.show_size_units || param_data.show_weight_units) && data[param]['unit'] && data[param]['unit']['label']) {
              name += ' ' + data[param]['unit']['label'];
            }
            name += ", ";
          }
          if (this.calculate_eval && param_data.eval && param_data.eval.parameters.length > 0) {
            let func_res = this.f_calculateEval(param_data.eval, data, param);
            if (func_res) {
              if (!data[param] === undefined) {
                data[param] = { 'val': '' };
              }
              data[param]['val'] = func_res.toString();
              // console.log('data', data);
            } else {
              if (data[param] !== undefined) {
                data[param] = { 'val': '' };
              }
            }
          }
        }
      } else {
        if (param_data.required && param_data.required === 'yes') {
          if (this.wdmr_require_alerts.indexOf(param_data.name) === -1) {
            this.wdmr_require_alerts.push(param_data.name);
          }
        } else {
          if (this.D) {
            delete data[param];
          }
          if (this.S) {
            delete schema[param];
          }
        }
      }
    } else {
      if (data['name']) {
        name += data['name'];
        this.only_one_param_is_filled = true;
      }
    }
    // console.log('other_parameter NAME: ', name);
    return name;
  };

  static f_calculateEval = function(param_eval_data, data, param) {
    // console.log('param ', param);
    // console.log('data ', data);
    // console.log('param_eval_data ', param_eval_data);
    let eval_str = '';
    for (let i in param_eval_data.parameters) {
      // console.log('i   ', i);
      let eval_data = param_eval_data.parameters[i];
      if (eval_data.type === 'wdm') {
        if (eval_data.mother_param && eval_data.mother_param === 'yes') {
          let eval_param_value = eval_data.value;
          if (data[eval_param_value] && data[eval_param_value].val) {
            // console.log(typeof data[eval_param_value].val);
            if (typeof data[eval_param_value].val !== 'object') {
              // console.log('data[eval_param_value]', data[eval_param_value]);
              eval_str += data[eval_param_value].val;
            }
          } else {
            return '';
          }
        } else {
          return '';
          // index location will be controlled for data.
        }
      } else if (eval_data.type === 'math_operator') {
        eval_str += eval_data.label;
      } else if (eval_data.type === 'manuel_parameter') {
        eval_str += eval_data.value;
      } else if (eval_data.type === 'prepared_parameter') {
        // index location will be controlled for data.
      }
      if (parseInt(i) !== param_eval_data.parameters.length - 1) {
        eval_str += ' ';
      } else {
        eval_str += ';';
      }
    }
    let result = '';
    // console.log('eval_str: ', eval_str);
    if (eval_str) {
      eval_str = 'result = ' + eval_str;
      try {
        eval(eval_str);
      } catch (err) {}
    }
    // console.log('eval function result =>  ', result);
    return result;
  };

  static list_parameter = function(list_data, upper_parameters, schema, watch_data, list_caption) {
    let name = "";
    let list_name = "";
    for (let i in list_data) {
      let x = false;
      // içinde kaç adet parametre varsa burada o kadar sayıda uyarı dönüyor
      let schema_data_location = schema ? schema[i] : schema;
      x = this.main_param_func(list_data[i], upper_parameters, schema_data_location, watch_data);
      watch_data.index_list.splice(watch_data.index_list.length - 1, 1);
      /*
      if ('val' in list_data[i]) {
        x = this.main_param_func(list_data, upper_parameters, schema);
      } else {
        let schema_data_location = schema ? schema[i] : schema;
        x = this.main_param_func(list_data[i], upper_parameters, schema_data_location);
      }
      */
      if (x) {
        if (this.N) {
          list_name += (parseInt(i) + 1).toString() + ")" + x;
        }
      }
    }
    if (list_name) {
      name = list_caption + "= " + list_name;
    }
    return name;
  };

  static object_list_options_parameter = function(data, upper_parameters, list_caption, schema, watch_data) {
    let name = "";
    let detail_name = "";
    for (let i in data) {
      /*
      if (data[i]['name']) {
        let schema_data_location = schema ? schema[i] : schema;
        this.main_param_func(data[i], upper_parameters, schema_data_location);
      }
      */
      // We are controlling the lower level parameters, below object_list_options parameter means below 'detail'
      let z = '';
      if (data[i].detail && Object.keys(data[i].detail).length > 0) {
        let x = false;
        let schema_data_location = schema ? schema[i].detail : schema;
        // x = this.list_parameter(data[i].detail, upper_parameters, data[i].name.label, schema[i].detail);
        x = this.main_param_func(data[i].detail, upper_parameters, schema_data_location, watch_data);
        watch_data.index_list.splice(watch_data.index_list.length - 1, 1);
        if (x) {
          if (this.N) {
            z += data[i].name.label + "; " + x;
          }
        }
      }
      // If the looping object_options_parameter has naming feature, but there is no naming parameters or no filled naming parameters in the lower levels. 
      // Then x comes as free string. Thus detail_name will be free string.
      if (!z) {
        if (this.N && upper_parameters.naming) {
          detail_name += data[i].name.label + ", ";
        }
      }
    }
    if (detail_name) {
      name = list_caption + "; " + detail_name;
    }
    return name;
  };
};

class ClsWdmrSave {
  static save = function(wdmr, wdm) {
    if (wdm.date_ability && wdm.date_ability === 'yes') {
      if (!wdmr.date) {
        let modal_data = { 'type': 'alert', 'text': "Lütfen tarih giriniz", 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        store.commit('modal', modal_data);
        store.commit('modal_show', true);
        return;
      }
    }
    let wdmr_name, wdmr_require_alerts, only_one_param_is_filled = ClsWdmrForever.turn(wdmr, wdm, ['require']);
    if (!only_one_param_is_filled) {
      let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
      store.commit('modal', modal_data);
      store.commit('modal_show', true);
      return;
    }
    // wdmr kaydı sırasında tüm wdm parametreleri için require kontrolü yapıyor. İlgili parametrede val değeri oluştuysa zaten oraya girilmiş oluyor. İçine girilipte (wisdom-param componenti) dolu olmayan tüm parametreleri kontrol ediyoruz. Örneğin er ihd testini ekledi. Fakat pozitif / negatif değerini doldurmadıysa geçemiyor.
    if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
      let require_txt = '';
      for (let r in wdmr_require_alerts) {
        require_txt += ' * ' + wdmr_require_alerts[r].label;
      }
      let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
      store.commit('modal', modal_data);
      store.commit('modal_show', true);
      return;
    }
  };
}

class ClsWdm {

  static combineWdmrList = function(wdmr_list, wdm, operation = []) {
    let result_wdmr = { 'data': {} };
    let new_wdmr_list = [];
    for (let wdmr_ind in wdmr_list) {
      let return_data = ClsWdmrForever.turn(wdmr_list[wdmr_ind], wdm, operation);
      new_wdmr_list.push(return_data.wdmr);
    }
    for (let i in new_wdmr_list) {
      let wdmr_data = new_wdmr_list[i];
      for (let pg in wdmr_data['data']) {
        if (pg === 'anatomy') {
          for (let ana in wdmr_data['data']['anatomy']) {
            let turning_data = { 'index_list': [pg], 'stop': false, 'op': '' };
            if (!result_wdmr['data']['anatomy']) {
              result_wdmr['data']['anatomy'] = {};
            }
            result_wdmr['data']['anatomy'][ana] = wdmr_data['data']['anatomy'][ana];
            this.turnOverWdmrParameters(wdm, wdmr_data['data']['anatomy'][ana]['signs'], turning_data, result_wdmr);
            if (turning_data['stop']) {
              break;
            }
          }
        } else {
          let turning_data = { 'index_list': [pg], 'stop': false, 'op': '' };
          this.turnOverWdmrParameters(wdm, wdmr_data['data'][pg], turning_data, result_wdmr);
          if (turning_data['stop']) {
            break;
          }
        }
      }
    }
    return result_wdmr;
  };

  static turnOverWdmrParameters = function(wdm_data, wdmr_paramlist, turning_data, result_wdmr) {
    if (!turning_data['stop']) {
      let i = 0;
      for (let param in wdmr_paramlist) {
        if (i === 0) {
          turning_data['index_list'].push(param);
        } else {
          turning_data['index_list'][turning_data['index_list'].length - 1] = param;
        }
        i += 1;
        let param_data = this.getParamDataWithIndexList(wdm_data, turning_data['index_list']);
        // console.log('param_data ', param_data);
        if (param_data) {
          if (param_data['type'] === 'list_parameter') {
            if (wdmr_paramlist[param] && wdmr_paramlist[param].list && wdmr_paramlist[param].list.length > 0) {
              for (let li_index in wdmr_paramlist[param].list) {
                let list_data = wdmr_paramlist[param].list[li_index];
                this.turnOverWdmrParameters(wdm_data, list_data, turning_data, result_wdmr);
                turning_data.index_list.splice(turning_data.index_list.length - 1, 1);
              }
            }
          } else if (param_data['type'] === 'object_list_options') {
            if (Object.keys(wdmr_paramlist[param]).length > 0) {
              for (let k in wdmr_paramlist[param]) {
                if (wdmr_paramlist[param][k].detail && Object.keys(wdmr_paramlist[param][k].detail).length > 0) {
                  this.turnOverWdmrParameters(wdm_data, wdmr_paramlist[param][k].detail, turning_data, result_wdmr);
                  turning_data.index_list.splice(turning_data.index_list.length - 1, 1);
                }
              }
            }
          } else if (param_data['type'] !== 'object_list') {
            if (wdmr_paramlist[param].val && wdmr_paramlist[param].val != '') {
              if (['select', 'radiogroup'].indexOf(param_data['type']) !== -1) {
                // console.log(param_data['type']);
              } else if (['checkbox'].indexOf(param_data['type']) !== -1) {
                //
              } else {
                //
              }
            }
          }
        }
      }
    }
  };

  static getParamDataWithIndexList = function(wdm_data, index_list) {
    // console.log(index_list);
    let param_data = false;
    let x = 'param_data = wdm_data';
    if (index_list.length > 1) {
      for (let i in index_list) {
        x += "['" + index_list[i] + "']";
        if (parseInt(i) != index_list.length - 1) {
          x += "['parameters']";
        }
      }
    } else {
      // here means we are getting the group data.
      x += '["param_group"]["parameters"]["' + index_list[0].toString() + '"]';
    }
    // console.log('x ', x);
    eval(x);
    return param_data;
  };

  static getParamMother = function(wdm_data, index_list) {
    // console.log(index_list);
    let param_mother = false;
    let x = 'param_mother = wdm_data';
    for (let i in index_list) {
      // we dont use last parameter. Because we will get the upper location data
      if (parseInt(i) != index_list.length - 1) {
        if (parseInt(i) > 0) {
          x += "['parameters']";
        }
        x += "['" + index_list[i] + "']";
      }
    }
    // console.log(x);
    eval(x);
    return param_mother;
  };

  static maxDeeperLevel = function(wdm) {
    // Example wdm => the data of wdm_wisdom_pathology_v2.0
    let maximum_deeper_level = 0;
    for (let pg_index in wdm.param_group.param_order) {
      let pg = wdm.param_group.param_order[pg_index];
      let x = { 'level': 0, 'index_list': [] };
      // Example wdm['general'] = {'parameters': {}, 'param_order': []};
      this.turnForeverInParameters(wdm[pg], x);
      if (x.level > maximum_deeper_level) {
        maximum_deeper_level = x.level;
      }
    }
    return maximum_deeper_level;
  };

  static turnForeverInParameters = function(param_list, x) {
    for (let pr_index in param_list['param_order']) {
      let param_value = param_list['param_order'][pr_index];
      if (parseInt(pr_index) === 0) {
        x.index_list.push(pr_index);
        if (x.index_list.length > x.level) {
          x.level = x.index_list.length;
        }
      } else {
        x.index_list[x.index_list.length - 1] = pr_index;
      }
      let param_data = param_list['parameters'][param_value];
      if (param_data.param_order && param_data.param_order.length > 0) {
        this.turnForeverInParameters(param_data, x);
        x.index_list.splice(x.index_list.length - 1, 1);
      }
    }
  };
}
export { ClsWdmrFreeValControl, ClsWdmrForever, ClsWdmrSave, ClsWdm };
