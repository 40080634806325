<script>
import { Scatter } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'

export default {
  props: {
    chart_data: {
      type: Object,
      required: true
    }
  },
  extends: Scatter,
  mounted () {
    // Overwriting base render method with actual data.
    this.renderChart(
      this.chart_data,
      /*
      {
        labels: ['Türkiye', 'Almanya'],
        datasets: [
          {
            label: 'Erkek',
            backgroundColor: '#f87979',
            data: [40, 20]
          },
          {
            label: 'Kadın',
            backgroundColor: 'green',
            data: [50, 28]
          }
        ]
      },
      */
      {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function (tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
            }
          }
        }
      }
    )
  }
}
</script>
