<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header">
      <b-card-header style="background-color: #a1e0d2; font-weight: bold;" class="p-1 text-center">
        HBYS tarafından gönderilen {{ p_himsData.name }} parametresi ile WisdomEra'ya ait {{ p_paramData.name.label }} parametresinin eşleştirilmesi
      </b-card-header>
      <template v-if="p_himsData.match_operations && p_himsData.match_operations.length > 0">
        <b-row>
          <b-col cols="5" class="text-center"> <span style="font-weight: bold;">HBYS Datası</span> </b-col>
          <b-col cols="1" class="text-center"> <span style="font-weight: bold;">İşlem</span> </b-col>
          <b-col cols="4" class="text-center"> <span style="font-weight: bold;">WisdomEra Datası</span> </b-col>
          <b-col cols="2" class="text-center"> <span style="font-weight: bold;">{{ $t('wdm16.3975') }}</span> </b-col>
        </b-row>
        <template v-for="(match_op, match_op_ind) in p_himsData.match_operations">
          <b-row :key="'match_operation_' + match_op_ind">
            <b-col cols="5">
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text @click="f_showConditionTextModal(match_op.condition)" style="cursor: pointer;"><i class="fa fa-search"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input :disabled="match_op.type === 'hims'" type="text" class="form-control-warning" :id="'condition_' + match_op_ind" :key="'condition_' + match_op_ind" v-model="match_op.condition" :state="match_op.condition.length > 0 ? true : false" placeholder="HBYS tarafından gelecek olan metni belirtin"></b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="1">
              <b-button :key="'dont_fill_parameter_' + match_op_ind" :variant="match_op.dont_fill_parameter ? 'danger' : 'success'" block @click="f_changeFillParameterOption(match_op_ind)"><i :class="['fa', match_op.dont_fill_parameter ? 'fa-hand-paper-o' : 'fa-check']"></i></b-button>
            </b-col>
            <b-col cols="4">
              <template v-if="['select'].indexOf(p_paramData.type) !== -1">
                <v-select :disabled="match_op.dont_fill_parameter" name="select" ref="select" :options="p_paramData.options" v-model="match_op.result">
                  <template slot="option" slot-scope="option">
                    <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                      <span> {{ option.label }} </span>
                    </div>
                  </template>
                </v-select>
              </template>
              <template v-else-if="['integer','float'].indexOf(p_paramData.type) !== -1">
                <input :disabled="match_op.dont_fill_parameter" v-model="match_op.result" class="form-control" type="number" name="number" :min="p_paramData.min ? p_paramData.min : ''" :max="p_paramData.max && p_paramData.max > 0 ? p_paramData.max : ''">
              </template>
              <template v-else-if="['text'].indexOf(p_paramData.type) !== -1">
                <input :disabled="match_op.dont_fill_parameter" v-model="match_op.result" class="form-control" type="text" name="text" :maxlength="p_paramData.max && p_paramData.max > 0 ? p_paramData.max : ''">
              </template>
            </b-col>
            <b-col cols="2">
              <b-button variant="danger" block style="width: 100%;" @click="p_himsData.match_operations.splice(match_op_ind, 1); $forceUpdate();"><i class="fa fa-trash"></i> Kaldır</b-button>
            </b-col>
          </b-row>
        </template>
      </template>
      <b-row>
        <b-col cols="12">
          <b-button variant="warning" block @click="f_addNewMatchOperation()"><i class="fa fa-plus"></i> Yeni Tanımlama Ekle</b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="card-accent-primary" header-tag="header">
      <b-card-header style="background-color: #a1e0d2; font-weight: bold;" class="p-1 text-center">
        Eşleştirme Hatası Datalar
      </b-card-header>
      <template v-if="p_himsData.data_not_match && p_himsData.data_not_match.length > 0">
        <b-row>
          <b-col cols="8" class="text-center"> <span style="font-weight: bold;">HBYS Datası</span> </b-col>
          <b-col cols="2" class="text-center"> <span style="font-weight: bold;">Eşleme Yap</span> </b-col>
          <b-col cols="2" class="text-center"> <span style="font-weight: bold;">Uyarma</span> </b-col>
        </b-row>
        <template v-for="(data_not_match, data_not_match_ind) in p_himsData.data_not_match">
          <b-row :key="'data_not_match_' + data_not_match_ind" :style="f_calculateRowStyle(data_not_match_ind)">
            <b-col cols="8">
              {{ data_not_match }}
            </b-col>
            <b-col cols="2">
              <b-button variant="danger" block style="width: 100%;" @click="f_dataNotMatchOperation(data_not_match_ind, 'match')"><i class="fa fa-trash"></i> Eşleme Yap</b-button>
            </b-col>
            <b-col cols="2">
              <b-button variant="danger" block style="width: 100%;" @click="f_dataNotMatchOperation(data_not_match_ind, 'dont_log')"><i class="fa fa-trash"></i> Uyarma</b-button>
            </b-col>
          </b-row>
        </template>
      </template>
    </b-card>
    <b-card class="card-accent-primary" header-tag="header">
      <b-card-header style="background-color: #a1e0d2; font-weight: bold;" class="p-1 text-center">
        Tekrar Uyarılmayacak Datalar
      </b-card-header>
      <template v-if="p_himsData.dont_log_match && p_himsData.dont_log_match.length > 0">
        <b-row>
          <b-col cols="8" class="text-center"> <span style="font-weight: bold;">HBYS Datası</span> </b-col>
          <b-col cols="2" class="text-center"> <span style="font-weight: bold;">Eşleme Yap</span> </b-col>
          <b-col cols="2" class="text-center"> <span style="font-weight: bold;">Listele</span> </b-col>
        </b-row>
        <template v-for="(dont_log_match, dont_log_match_ind) in p_himsData.dont_log_match">
          <b-row :key="'dont_log_match_' + dont_log_match_ind" :style="f_calculateRowStyle(dont_log_match_ind)">
            <b-col cols="8">
              {{ dont_log_match }}
            </b-col>
            <b-col cols="2">
              <b-button variant="danger" block style="width: 100%;" @click="f_dontLogMatchOperation(dont_log_match_ind, 'match')"><i class="fa fa-trash"></i> Eşleme Yap</b-button>
            </b-col>
            <b-col cols="2">
              <b-button variant="danger" block style="width: 100%;" @click="f_dontLogMatchOperation(dont_log_match_ind, 'log')"><i class="fa fa-trash"></i> Listele</b-button>
            </b-col>
          </b-row>
        </template>
      </template>
    </b-card>
    <b-modal v-if="d_showConditionModal" id="d_showConditionModal" v-model="d_showConditionModal" title="HBYS Datası" centered header-bg-variant="info" header-text-variant="dark" hide-footer>
      <b-row>
        <b-col cols="12">
          {{ d_conditionModalData }}
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import auth from '@/auth';
import vSelect from "vue-select";
import Vue from 'vue';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from 'wisdom-config';
import {
  default as router
} from '@/router';
import { API_BASE_URL } from '@/config';

export default {
  name: 'HimsMatchOperations',
  computed: {
    ...mapGetters({})
  },
  props: {
    p_paramData: {
      type: Object,
      required: false
    },
    p_himsData: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      d_showConditionModal: false,
      d_conditionModalData: '',
      d_emptyMatchOperationLine: { 'condition': '', 'result': '', 'type': 'manual', 'dont_fill_parameter': 0 }
    };
  },
  created: function () {},
  methods: {
    f_showConditionTextModal: function (condition) {
      this.d_conditionModalData = condition;
      this.d_showConditionModal = true;
    },
    f_changeFillParameterOption: function (match_op_ind) {
      if (!this.p_himsData.match_operations[match_op_ind].dont_fill_parameter) {
        this.p_himsData.match_operations[match_op_ind].dont_fill_parameter = 1;
      } else {
        this.p_himsData.match_operations[match_op_ind].dont_fill_parameter = 0;
      }
      this.$forceUpdate();
    },
    f_dataNotMatchOperation: function (data_not_match_ind, op_type) {
      if (op_type === 'match') {
        if (!this.p_himsData.match_operations) {
          this.p_himsData.match_operations = [];
        }
        this.p_himsData.match_operations.push({ 'condition': this.p_himsData.data_not_match[data_not_match_ind], 'result': '', 'type': 'hims', 'dont_fill_parameter': 0 });
        this.p_himsData.data_not_match.splice(data_not_match_ind, 1);
        this.$forceUpdate();
      } else {
        if (!this.p_himsData.dont_log_match) {
          this.p_himsData.dont_log_match = [];
        }
        this.p_himsData.dont_log_match.push(this.p_himsData.data_not_match[data_not_match_ind]);
        this.p_himsData.data_not_match.splice(data_not_match_ind, 1);
        this.$forceUpdate();
      }
    },
    f_dontLogMatchOperation: function (dont_log_match_ind, op_type) {
      if (op_type === 'match') {
        if (!this.p_himsData.match_operations) {
          this.p_himsData.match_operations = [];
        }
        this.p_himsData.match_operations.push({ 'condition': this.p_himsData.dont_log_match[dont_log_match_ind], 'result': '', 'type': 'hims', 'dont_fill_parameter': 0 });
        this.p_himsData.dont_log_match.splice(dont_log_match_ind, 1);
        this.$forceUpdate();
      } else {
        if (!this.p_himsData.data_not_match) {
          this.p_himsData.data_not_match = [];
        }
        this.p_himsData.data_not_match.push(this.p_himsData.dont_log_match[dont_log_match_ind]);
        this.p_himsData.dont_log_match.splice(dont_log_match_ind, 1);
        this.$forceUpdate();
      }
    },
    f_calculateRowStyle: function (row_ind) {
      if (row_ind % 2 === 0) {
        return 'background-color: #c8ced3';
      } else {
        return '';
      }
    },
    f_addNewMatchOperation: function () {
      if (!this.p_himsData.match_operations) {
        this.p_himsData.match_operations = [];
      }
      this.p_himsData.match_operations.push(JSON.parse(JSON.stringify(this.d_emptyMatchOperationLine)));
      this.$forceUpdate();
    }
  },
  watch: {},
  components: {
    vSelect
  }
};

</script>

