import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  analyze_with_statistic_models(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/analyze_with_statistic_models', data);
  },
  column_update_selected_projects(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/column_update_selected_projects', data);
  },
  project_settings_auto_time(query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/project/auto/time?' + query, data);
  },
  project_stats_report_and_module_update(query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/project/stats/report_and_module/update?' + query, data);
  },
  get_project_cases(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/get_project_cases', data);
  },
  prepare_project_cases(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/prepare_project_cases', data);
  },
  prepare_project_cases_cancelled(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/prepare_project_cases_cancelled', data);
  },
  save_data_set(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/save_data_set', data);
  },
  add_text_list_to_article(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/add_text_list_to_article', data);
  },
  create_all_text_of_wdm72_results(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/create_all_text_of_wdm72_results', data);
  },
  create_pdf_of_wdm72_results(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/create_pdf_of_wdm72_results', data);
  },
  analyze_model(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/analyze_model', data);
  },
  get_analyze_data(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/get_analyze_data', data);
  },
  save_statistics(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/save_statistics', data);
  },
  save_wdm73(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/save_wdm73', data);
  },
  save_polestar_project(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/save_polestar_project', data);
  },
  save_wdm308_to_col(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/save_wdm308_to_col', data);
  },
  delete_wdm72_data(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/delete_wdm72_data', data);
  },
  delete_wdm163_data(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/delete_wdm163_data', data);
  },
  delete_wdm73_data(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/delete_wdm73_data', data);
  },
  get_user_polestar_projects(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/get_user_polestar_projects', data);
  },
  transfer_global_polestar_project(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/transfer_global_polestar_project', data);
  },
  create_polestar_user(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/create_polestar_user', data);
  },
  edit_column_list(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/edit_column_list', data);
  },
  save_column_rule_filter(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/save_column_rule_filter', data);
  },
  delete_column_rule_filter(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/delete_column_rule_filter', data);
  },
  transfer_dataset_to_global(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/transfer_dataset_to_global', data);
  },
  add_new_rows(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/add_new_rows', data);
  },
  analyze_fast_model(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/analyze_fast_model', data);
  },
  save_project_copy(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/save_project_copy', data);
  },
  publish_as_waim(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/publish_as_waim', data);
  },
  search_waim(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/search_waim', data);
  },
  delete_polestar_project(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/delete_polestar_project', data);
  },
  print_article_pdf(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/print_article_pdf', data);
  },
  score_this_wdm72_result(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/score_this_wdm72_result', data);
  },
  save_excel_file_to_polestar(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/save_excel_file_to_polestar', data);
  },
  add_id_list_to_dataset(data) {
    return Vue.http.post(API_BASE_URL + 'v1/id_list/dataset/add', data);
  },
  delete_dataset_row(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/delete_dataset_row', data);
  },
  add_new_row(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/add_new_row', data);
  },
  combine_with_this_patient(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/combine_with_this_patient', data);
  },
  update_patient_wdmr_cells(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/update_patient_wdmr_cells', data);
  },
  update_wdmr_cells(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/update_wdmr_cells', data);
  },
  analyze_machine_learning(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/analyze_machine_learning', data);
  },
  save_machine_learning_model(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/save_machine_learning_model', data);
  },
  train_machine_learning_model(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/train_machine_learning_model', data);
  },
  find_text_in_hims_protocol_info(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/find_text_in_hims_protocol_info', data);
  },
  search_text_analyze_inspection(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/search_text_analyze_inspection', data);
  },
  search_text_analyze_on_wdmrs(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/search_text_analyze_on_wdmrs', data);
  },
  make_auto_matching_for_hims_options(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/make_auto_matching_for_hims_options', data);
  },
  update_cells_by_column_option_rule(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/update_cells_by_column_option_rule', data);
  },
  update_cells_by_wdmr_rule(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/update_cells_by_wdmr_rule', data);
  },
  update_cells_by_column_option_mixed_wdmr_rule(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/update_cells_by_column_option_mixed_wdmr_rule', data);
  },
  update_all_project_cells_by_following_rule_hierarchy(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/update_all_project_cells_by_following_rule_hierarchy', data);
  },
  update_selected_project_cells_by_following_rule_hierarchy(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/update_selected_project_cells_by_following_rule_hierarchy', data);
  },
  calculate_auto_filled_cells_from_hims(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/calculate_auto_filled_cells_from_hims', data);
  },
  analyze_project_cell_fill_methods(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/analyze_project_cell_fill_methods', data);
  },
  download_project_cell_fill_method_analyze_excel(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/download_project_cell_fill_method_analyze_excel', data);
  },
  get_this_project(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/get_this_project', data);
  },
  create_text_of_rule_algoritm(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/create_text_of_rule_algoritm', data);
  },
  update_column_options_by_dataset(data) {
    return Vue.http.post(API_BASE_URL + 'polestar/update_column_options_by_dataset', data);
  },
  update_cells_for_all_manuel_columns(data) {
    return Vue.http.post(API_BASE_URL + 'v1/update_cells_for_all_manuel_columns', data);
  },
  get_api_list(query) {
    return Vue.http.post(API_BASE_URL + 'v1/get_api_list?' + query);
  },
  article_report_by_stat_results(query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/article/report/by_stat_results?' + query, data);
  },
  project_merge_dataset(query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/project/merge/dataset?' + query, data);
  },
  project_column_option_assign(query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/project/column/option/assign?' + query, data);
  },
  analyze_by_layer_data_id(query, data) {
    return Vue.http.post(API_BASE_URL + 'v1/analyze/by/layer/data_id?' + query, data);
  }
};
